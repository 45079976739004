import * as React from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { FONTS } from '../../../../constants'
import { Domain } from '../../../../core'
import Email from './Email'
import Faq from './Faq'
import MembersCardNumber from './MembersCardNumber'
import RegisterButton from './RegisterButton'
import StaffCode from './StaffCode'

const styles = StyleSheet.create({
  headline: {
    fontSize: 20,
    fontFamily: FONTS.MAIN_FONT,
    marginTop: 28,
    marginHorizontal: 16,
    marginBottom: 0,
    textAlign: 'center',
  },
  button: {
    marginTop: 28,
  },
})

interface Props {
  membersCard: string | undefined
  registerMember: (request: Domain.MembersCard.Entity) => void
}

export default function Form(props: Props) {
  const [membersCardNumber, setMembersCardNumber] = React.useState('')
  const [membersCardNumberValidation, setMembersCardNumberValidation] = React.useState(false)

  const [email, setEmail] = React.useState('')
  const [emailValidation, setEmailValidation] = React.useState(false)

  const [staffCode, setStaffCode] = React.useState('')
  const [staffCodeValidation, setStaffCodeValidation] = React.useState(true)

  const buttonEnabled = membersCardNumberValidation && emailValidation && staffCodeValidation

  const removeSpaces = (input: string): string => {
    return input.replace(/\s/g, '')
  }

  const handleOnPressPurchase = () => {
    const optional = staffCode ? { staffCode } : {}
    const registerMember: Domain.MembersCard.Entity = Object.assign(
      {
        email,
        // Remove whitespaces before submission
        membersCardNo: removeSpaces(membersCardNumber),
      },
      optional,
    )
    props.registerMember(registerMember)
  }

  return (
    <View>
      <View>
        <Text style={styles.headline}>メンバーズカードをお手元にご用意し、メンバーズカード情報を登録してください</Text>
      </View>
      <View>
        <View>
          <MembersCardNumber
            membersCardNumber={membersCardNumber}
            setMembersCardNumber={setMembersCardNumber}
            setMembersCardNumberValidation={setMembersCardNumberValidation}
          />
          <Email email={email} setEmail={setEmail} setEmailValidation={setEmailValidation} />
          <StaffCode
            staffCode={staffCode}
            setStaffCode={setStaffCode}
            setStaffCodeValidation={setStaffCodeValidation}
          />
        </View>
      </View>

      <View>
        <RegisterButton registerMember={handleOnPressPurchase} style={styles.button} disabled={!buttonEnabled} />
        <Faq />
      </View>
    </View>
  )
}
