import * as React from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { COLORS, FONTS } from '../../../constants'

const styles = StyleSheet.create({
  container:{
    marginTop: 28,
    alignItems: 'center',
  },
  textWrapper: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop:8,
  },
  textLabel: {
    fontSize: 16,
    fontFamily: FONTS.MAIN_FONT,
  },
  ticketTotalLabel: {
    fontSize: 20,
    fontFamily: FONTS.MAIN_FONT,
    marginHorizontal: 5,
    color: COLORS.ACCENT,
  },
})

interface Props {
  ticketTotal: number
}

export default function TicketTotal(props: Props) {
  return (
    <View style={styles.container}>
      <Text style={styles.textLabel}>今まで集めたチケット</Text>
      <View style={styles.textWrapper}>
        <Text style={styles.ticketTotalLabel}>{props.ticketTotal}</Text>
        <Text style={styles.textLabel}>枚</Text>
      </View>

    </View>
  )
}
