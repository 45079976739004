import * as Domain from '../domain'

type CustomerFeedback = Array<Domain.LINECoupon.Survey>

const initialState = [{ question: '', answer: '' }]

export function createInitialState(): CustomerFeedback {
  return initialState
}

export const SET_CUSTOMER_FEEDBACK = 'customer-feedback/set' as const
export const CLEAR_CUSTOMER_FEEDBACK = 'customer-feedback/clear' as const

export function setCustomerFeedback(feedback: CustomerFeedback) {
  return {
    type: SET_CUSTOMER_FEEDBACK,
    payload: {
      feedback,
    },
  }
}

export function clearCustomerFeedback() {
  return {
    type: CLEAR_CUSTOMER_FEEDBACK,
  }
}

type SetCustomerFeedbackAction = ReturnType<typeof setCustomerFeedback>
type ClearCustomerFeedbackAction = ReturnType<typeof clearCustomerFeedback>
type Action = SetCustomerFeedbackAction | ClearCustomerFeedbackAction

export function reducer(state = createInitialState(), action: Action) {
  switch (action.type) {
    case SET_CUSTOMER_FEEDBACK:
      return action.payload.feedback
    case CLEAR_CUSTOMER_FEEDBACK:
      return initialState
    default:
      return state
  }
}
