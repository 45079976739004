import React from 'react'
import { useSelector } from 'react-redux'
import { Modules, Selectors } from '../core'

import LoadingPanel from '../components/LoadingPanel'

export default function LoadingPanelContainer() {
  const needsToShow = useSelector((state: Modules.AppState) => Selectors.Network.isCommunicating(state))
  return <LoadingPanel needsToShow={needsToShow} />
}
