import * as React from 'react'
import { StyleSheet, Text, View } from 'react-native'
import Footer from '../../common/Footer'

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 16,
  },
  headline: {
    fontSize: 20,
    textAlign: 'center',
    marginVertical: 28,
  },
  contentSection: {
    marginBottom: 30,
  },
  content: {
    fontSize: 16,
    letterSpacing: 0.3,
    lineHeight: 26,
  },
})

export default function PrivacyPolicy() {
  return (
    <View style={styles.container}>
      <Text style={styles.headline}>
        アイケアアンバサダー{'\n'}byビジョナリーHD{'\n'}プライバシーポリシー
      </Text>

      <View style={styles.contentSection}>
        <Text style={styles.content}>
          {'\n'}
          株式会社ビジョナリーホールディングス及びそのグループ会社
          （グループ会社は当社ホームページに掲載されています。以下、当社とグループ会社を合わせて「当社等」といいます。）
          は、お客様（「会員」、「会員登録の申込者」、及び下記「本サービスの閲覧者」を総称していいます。）
          の個人を識別しうる情報を適切に保護することが社会的責務として重要と考え、下記「プライバシーポリシー」に基づき、
          お客様の個人情報を取得、利用、第三者に預託または提供させていただきます。
          {'\n'}
          {'\n'}
          当社等は、当社等の事業の用に供するすべての個人情報を適切に取扱うため、
          当社全従業者が遵守すべき行動基準として本プライバシーポリシーを定め、
          その遵守の徹底を図ることといたします。
          {'\n'}
          {'\n'}
          お客様が、当社等の提供するウェブサービスである「アイケアアンバサダーbyビジョナリーHD」
          （以下「本ウェブサービス」といいます。）をご利用されるためには、下記「お客様情報の取扱規定
          （プライバシーポリシー）」の内容に同意していただく必要があります。同意いただけない場合は、
          本ウェブサービスへのアクセス及び本サービスのご利用はお断りします。
        </Text>
      </View>

      <View style={styles.contentSection}>
        <Text style={styles.content}>
          プライバシーポリシー
          {'\n'}
          {'\n'}
          1. 個人情報の適用範囲
          {'\n'}
          お客様が本ウェブサービスをご利用いただく場合には、
          本プライバシーポリシーに同意していただいたものとみなされます。
          また、本ウェブサービスをご利用される場合、そのご利用及び個人情報に関するあらゆる紛争については、
          本プライバシーポリシー及び利用規約（免責事項、準拠法、管轄裁判所を含む）が適用されます。
        </Text>
      </View>

      <View style={styles.contentSection}>
        <Text style={styles.content}>
          2.個人情報の取扱い
          {'\n'}
          当社等は、個人情報保護を目的とする管理体制を確立し、
          個人情報の収集、利用、提供において、所定の規則に従って適切に取扱います。
          当社等は、お客様がお取引を完了しておらず、情報の入力途中であった場合においても、
          当社等のプライバシーポリシーに従い、当サービスの会員登録ページ/ご注文方法の指定ページに入力されたお客様の情報を取得し、
          当社等がその情報を用いてお客様へご質問/お取引に関するご連絡をさせていただく場合がございます。
          但し当社等は、当社等のプライバシーポリシーに掲げられた目的以外に、ご入力いただいた個人情報を利用することはございません。
        </Text>
      </View>

      <View style={styles.contentSection}>
        <Text style={styles.content}>
          3.安全対策
          {'\n'}
          当社等は、個人情報の安全性確保の重要性を常に認識し、
          不正アクセス、紛失、破壊、改鼠、漏洩等の起きないよう、
          教育その他適切な手段を用いて情報セキュリティ対策を実施し、
          また、必要に応じて常にセキュリティの仕組のチェック・見直しを行います。
        </Text>
      </View>

      <View style={styles.contentSection}>
        <Text style={styles.content}>
          4.法令・規範の遵守
          {'\n'}
          当社等は、個人情報の取り扱いにおいて、
          個人情報保護に関する諸法令、諸規範及び社内規定を遵守します。

        </Text>
      </View>

      <View style={styles.contentSection}>
        <Text style={styles.content}>
        5.実践的遵守計画の策定及び継続的改善
          {'\n'}
          当社等は、個人情報保護に関する諸法令及び諸規範を遵守する為、
          個人情報の保護に関する内部規定（コンプライアンス・プログラム）を定め、
          役職員の全てに周知徹底するほか、常に社会の動きを注視しつつ定期的見直しを行うなど、
          適切な管理を維持します。
        </Text>
      </View>

      <View style={styles.contentSection}>
        <Text style={styles.content}>
        6.個人情報の利用範囲・影響
          {'\n'}
          お客様より収集させていただいた個人情報は当社等内において
          お客様サービスの向上及び景品抽選など目的の範囲内でのみ利用させていただき、
          次の場合を除き、「秘密保持契約」を締結した第三者以外には開示または提供いたしません。
          {'\n'}・お客様の同意、合意など許可を得た場合
          {'\n'}・法令等によって開示・提供要請があった場合
        </Text>
      </View>

      <View style={styles.contentSection}>
        <Text style={styles.content}>
          7. プライバシーポリシーの変更
          {'\n'}
          本ポリシーの内容は、事前に通知することなく変更することができるものとし、
          当社が別途定める場合を除いて変更後のプライバシーポリシーは、
          本サービスに掲載したときから効力を生じるものとします。
          </Text>
      </View>

      <View style={styles.contentSection}>
        <Text style={styles.content}>
          ■個人情報についてのお問い合わせ
          {'\n'}
          当社等は、個人情報の利用・提出・開示 又は訂正等に関するお問合せ、個人情報の開示請求、
          その他の個人情報取り扱いに関するご意見・ご要望について、以下の窓口を設置し適切な対応に努めます。
          {'\n'}
          {'\n'}【電話でのお問合せ窓口】
          {'\n'} 〒103-0012　
          {'\n'}東京都中央区日本橋堀留町1-9-11 NEWS日本橋堀留町 6階
          {'\n'}株株式会社ビジョナリーホールディングス
          {'\n'}お問い合わせ受付時間：10:00～19:00
          {'\n'}フリーダイヤル ： 0120-209-060
          {'\n'}
        </Text>
      </View>
      <Footer />
    </View>
  )
}
