import React from 'react'
import { useSelector } from 'react-redux'
import ShareTargetPicker from '../components/screens/ShareTargetPicker'
import { Modules } from '../core'

export default function ShareTargetPickerContainer() {
  const couponContents = useSelector((state: Modules.AppState) => state.couponContents)
  const couponData = useSelector((state: Modules.AppState) => state.couponData)
  const customerFeedback = useSelector((state: Modules.AppState) => state.customerFeedback)

  return (
    <ShareTargetPicker customerFeedback={customerFeedback} couponContents={couponContents} couponData={couponData} />
  )
}
