import * as React from 'react'
import { StyleSheet, Text, TextInput, View } from 'react-native'
import { COLORS, FONTS } from '../../../../constants'
import ValidationErrors from '../../../common/ValidationErrors'
import { validateEmail, validateInputMaxLength, validateInputMinLength } from './FormValidation'

const styles = StyleSheet.create({
  label: {
    marginLeft: 8,
    fontSize: 16,
    fontFamily: FONTS.MAIN_FONT,
  },
  requiredLabel: {
    fontSize: 16,
    minWidth: 42,
    textAlign: 'center',
    fontFamily: FONTS.MAIN_FONT,
    color: COLORS.BACKGROUND,
    backgroundColor: COLORS.ACCENT,
    padding: 4,
    letterSpacing: 0,
  },
  labelWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    marginHorizontal: 16,
  },
  input: {
    height: 48,
    margin: 12,
    marginHorizontal: 16,
    borderWidth: 1,
    padding: 8,
    fontSize: 16,
    textAlign: 'left',
    letterSpacing: 0.5,
  },
  topSpacing: {
    marginTop: 28,
  },
})
const MIN_LENGTH = 1
const MAX_LENGTH = 256
const ERR_MESSAGE_MIN = 'メールアドレスを入力してください'
const ERR_MESSAGE_MAX = 'メールアドレスは' + MAX_LENGTH + '字以内で入力してください'
const ERR_INVALID_EMAIL = '有効なメールアドレスを入力してください'

interface Props {
  // Email
  email: string
  setEmail: (email: string) => void
  setEmailValidation: (isValid: boolean) => void
}

export default function Email(props: Props) {
  const { email: value, setEmail, setEmailValidation: setValid } = props

  const [errors, setErrors] = React.useState<Array<string>>([])
  const validateAll = React.useCallback(() => {
    const errorMessages = [
      validateInputMinLength(value, MIN_LENGTH, ERR_MESSAGE_MIN),
      validateInputMaxLength(value, MAX_LENGTH, ERR_MESSAGE_MAX),
      validateEmail(value, ERR_INVALID_EMAIL),
    ].flat()
    setErrors(errorMessages)
    setValid(errorMessages.length === 0)
  }, [value, setErrors, setValid])

  const onChangeText = (newValue: string) => {
    setEmail(newValue)
  }
  const isFirstRendering = React.useRef(true)
  React.useEffect(() => {
    if (isFirstRendering.current) {
      isFirstRendering.current = false
      return
    }
    validateAll()
  }, [validateAll])

  return (
    <View style={styles.topSpacing}>
      <View style={styles.labelWrapper}>
        <Text style={styles.requiredLabel}>必須</Text>
        <Text style={styles.label}>メールアドレスを入力してください</Text>
      </View>
      <ValidationErrors errors={errors} />
      <TextInput
        onChangeText={onChangeText}
        value={value}
        style={styles.input}
        autoCapitalize="none"
        autoCorrect={false}
        clearButtonMode="unless-editing"
        keyboardType="email-address"
        textContentType="emailAddress"
        placeholder="tarou@example.com"
        placeholderTextColor={COLORS.LIGHTGRAY}
      />
    </View>
  )
}
