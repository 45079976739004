import * as React from 'react'
import { useHistory } from 'react-router-dom'
import * as Domain from '../../../core/domain'
import styles from './ApiErrorAlert.module.css'
import ModalErrorAlert from './ModalErrorAlert'
import ScreenEmbeddedErrorAlert from './ScreenEmbeddedErrorAlert'

interface Props {
  error: Domain.RecoverableError | Error | null
  actions: {
    clearError: () => void
  }
}

function isRecoverableError(target: Domain.RecoverableError | Error): target is Domain.RecoverableError {
  return (target as Domain.RecoverableError).label !== undefined
}

export default function ApiErrorAlert(props: Props) {
  const {
    error,
    actions: { clearError },
  } = props

  const closeModal = () => {
    clearError()
  }

  useHistory().listen(() => {
    clearError()
  })

  if (error == null) {
    return null
  } else if (isRecoverableError(error)) {
    return (
      <div className={styles.container}>
        <ScreenEmbeddedErrorAlert error={error} />
      </div>
    )
  } else {
    return (
      <div className={styles.container}>
        <ModalErrorAlert error={error} onPress={closeModal} />
      </div>
    )
  }
}
