import * as React from 'react'
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { COLORS, FONTS } from '../../../constants'
import { useNavigate } from '../../../lib/hooks'

const styles = StyleSheet.create({
  headlineWrapper: {
    marginTop: 28,
  },
  headline: {
    fontSize: 20,
    fontFamily: FONTS.MAIN_FONT,
    marginHorizontal: 16,
    marginBottom: 0,
    textAlign: 'center',
  },
  labelWrapper: {
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'space-between',
  },
  container: {
    height: 84,
    marginTop: 28,
    marginHorizontal: 16,
    marginVertical: 24,
    marginBottom: 40,
    backgroundColor: COLORS.LINE,
    borderStyle: 'solid',
    borderColor: COLORS.PRIMARY,
    borderWidth: 1,
  },
  button: {
    marginLeft: 44,
    fontSize: 20,
    color: COLORS.WHITE,
    marginVertical: 'auto',
  },
  arrow: {
    width: 30,
    fontSize: 16,
    color: COLORS.WHITE,
    marginVertical: 'auto',
  },
})

export default function ShareOptionMenu() {
  const navigate = useNavigate('/send-line-coupon', {})

  const handleOnPress = () => {
    navigate()
  }

  return (
    <View>
      <View style={styles.headlineWrapper}>
        <Text style={styles.headline}>お客様情報を確認し、</Text>
        <Text style={styles.headline}>クーポンの送り方を選ぶ</Text>
      </View>
      <View>
        <TouchableOpacity onPress={handleOnPress} style={styles.container}>
          <View style={styles.labelWrapper}>
            <Text style={styles.button}>LINEで送る</Text>
            <Text style={styles.arrow}>＞</Text>
          </View>
        </TouchableOpacity>
      </View>
    </View>
  )
}
