import { Dispatch } from 'redux'
import { Api } from '../../lib'
import { MembersCard } from '../domain'
import { setMembersCard } from '../modules/membersCard'

type Data = MembersCard.Entity

function didCallGetMembersCardApi(dispatch: Dispatch, data: Data) {
  dispatch(setMembersCard(data))
}

export function getMembersCard(reloader: () => void) {
  const options = {
    reload: reloader,
  }

  return async function(dispatch: Dispatch) {
    await Api.callApi(Api.HttpMethod.get, 'cards', null, dispatch, didCallGetMembersCardApi, options)
  }
}
