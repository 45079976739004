import { Dispatch } from 'redux'
import { Domain } from '..'
import { Api } from '../../lib'
import { setTicketRedeemResult } from '../modules/ticketRedeemResult'
import { setTicket } from '../modules/tickets'

type Data = Domain.Ticket.Entity

function didCallFetchRedeemTicketApi(dispatch: Dispatch, data: Data) {
  dispatch(setTicketRedeemResult(data))
  dispatch(setTicket(data))
}

// Normal, Special ticket uses the same api function to redeem.
export function redeemTicket(ticketId: string, incentiveType: string) {
  return async function(dispatch: Dispatch) {
    await Api.callApi(
      Api.HttpMethod.post,
      `tickets/${ticketId}/redeem`,
      JSON.stringify({ incentiveType }),
      dispatch,
      didCallFetchRedeemTicketApi,
    )
  }
}
