import * as React from 'react'
import { ScrollView, StyleSheet, Text, View } from 'react-native'
import Footer from '../../common/Footer'
import QA from './QA'
import { ABOUT_COUPON, ABOUT_LINE_DATA_INHERITANCE, ABOUT_LINE_PERSONAL_DATA, ABOUT_MEMBERSHIP, ABOUT_PROGRAM, ABOUT_TICKET, FAQ as FAQ_CONTENT } from './QAContents'
import QAList from './QAList'

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 16,
  },
  headline: {
    fontSize: 20,
    textAlign: 'center',
    marginVertical: 28,
  },
  contentSection: {
    marginBottom: 30,
  },
  content: {
    fontSize: 16,
    letterSpacing: 0.3,
    lineHeight: 26,
  },
})

export default function FAQ() {
  const anchor = window.location.hash
  React.useEffect(() => {
    const id = anchor.split('#')[1]
    const element = document.getElementById(id)
    if (element !== null) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })
    }
  }, [anchor])

  const createQALinks = (faq: FAQ_CONTENT, i: number) => {
    return <QAList key={i} id={faq.id} title={faq.question} />
  }

  const createQAs = (faq: FAQ_CONTENT, i: number) => {
    return <QA key={i} qaId={faq.id} headline={faq.question} answer={faq.answer} />
  }

  const createFAQContents = (faqs: FAQ_CONTENT[]) => {
    const qaList = faqs.map(createQALinks)
    const qa = faqs.map(createQAs)
    return { qaList, qa }
  }

  const aboutProgram = createFAQContents(ABOUT_PROGRAM)
  const aboutMembership = createFAQContents(ABOUT_MEMBERSHIP)
  const aboutCoupon = createFAQContents(ABOUT_COUPON)
  const aboutTicket = createFAQContents(ABOUT_TICKET)
  const aboutLINEPersonalData = createFAQContents(ABOUT_LINE_PERSONAL_DATA)
  const aboutLINEDataInheritance = createFAQContents(ABOUT_LINE_DATA_INHERITANCE)

  const sections = [
    { title: '■アイケアアンバサダーについて', contents: aboutProgram },
    { title: '■会員登録について', contents: aboutMembership },
    { title: '■クーポンについて', contents: aboutCoupon },
    { title: '■紹介元ユーザーに付与される特典チケットについて', contents: aboutTicket },
    { title: '■LINE上の情報について', contents: aboutLINEPersonalData },
    { title: '■LINEアカウントの引継ぎについて', contents: aboutLINEDataInheritance },
  ]

  return (
    <View>
    <ScrollView style={styles.container}>
      <Text style={styles.headline}>アイケアアンバサダー{'\n'}Q&A</Text>

      {sections.map((section, i: number) => {
        return (
          <View key={i} style={styles.contentSection}>
            <Text style={styles.content}>{section.title}</Text>
            {section.contents.qaList}
          </View>
        )
      })}

      {sections.map((section, i: number) => {
        return (
          <View key={i} style={styles.contentSection}>
            <Text style={styles.content}>{section.title}</Text>
            {section.contents.qa}
          </View>
        )
      })}
    </ScrollView>
    <Footer />
    </View>
  )
}
