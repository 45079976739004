import * as React from 'react'
import { StyleSheet, Text, TextInput, View } from 'react-native'
import { COLORS, FONTS } from '../../../../constants'

const styles = StyleSheet.create({
  label: {
    marginLeft: 8,
    fontSize: 16,
    fontFamily: FONTS.MAIN_FONT,
    textAlign: 'left',
  },
  optionalLabel: {
    fontSize: 16,
    minWidth: 42,
    textAlign: 'center',
    fontFamily: FONTS.MAIN_FONT,
    color: COLORS.BACKGROUND,
    backgroundColor: COLORS.LIGHTGRAY,
    padding: 4,
    letterSpacing: 0,
  },
  labelWrapper: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    textAlign: 'center',
    marginHorizontal: 16,
  },
  topSpacing: {
    marginTop: 28,
  },
  commentBox: {
    fontSize: 16,
    height: 124,
    marginHorizontal: 16,
    marginTop: 16,
    borderWidth: 1,
    borderColor: COLORS.PRIMARY,
    padding: 12,
  },
})

interface Props {
  // Survey3
  thirdSurveyQuestion: string
  thirdSurveyAnswer: string
  setThirdSurveyAnswer: (comment: string) => void
}

export default function ThirdSurvey(props: Props) {
  const { thirdSurveyQuestion, thirdSurveyAnswer, setThirdSurveyAnswer } = props

  const onChangeValue = (newValue: string) => {
    setThirdSurveyAnswer(newValue)
  }

  return (
    <View style={styles.topSpacing}>
      <View style={styles.labelWrapper}>
        <Text style={styles.optionalLabel}>任意</Text>
        <Text style={styles.label}>{thirdSurveyQuestion}</Text>
      </View>

      <TextInput
        style={styles.commentBox}
        onChangeText={text => onChangeValue(text)}
        value={thirdSurveyAnswer}
        maxLength={500}
        multiline
        placeholder="メガネの検査が充実してて驚いた！作ったメガネも快適だよ！良かったらクーポン使ってね！"
      />
    </View>
  )
}
