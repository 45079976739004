export function createInitialState(): number | null {
  return null
}

export const SET_PAGINATION = 'pagination/set' as const
export const CLEAR_PAGINATION = 'pagination/clear' as const

export function setPaginationNumber(paginationNumber: number) {
  return {
    type: SET_PAGINATION,
    payload: {
      paginationNumber,
    },
  }
}

export function clearPaginationNumber() {
  return {
    type: CLEAR_PAGINATION,
  }
}

type SetPaginationNumberAction = ReturnType<typeof setPaginationNumber>
type ClearPaginationNumberAction = ReturnType<typeof clearPaginationNumber>
export type Action = SetPaginationNumberAction | ClearPaginationNumberAction

export function reducer(state = createInitialState(), action: Action) {
  switch (action.type) {
    case SET_PAGINATION:
      return action.payload.paginationNumber
    case CLEAR_PAGINATION:
      return null
    default:
      return state
  }
}
