import * as React from 'react'
import { StyleSheet, Text, TouchableOpacity } from 'react-native'
import Modal from '../Modal'


const styles = StyleSheet.create({
  modalButton: {
    justifyContent: 'center',
    alignItems: 'center',
    marginVertical: 20,
  },
  modalButtonLabel: {
    fontSize: 17,
    height: 30,
  },
})

interface Props {
  error: Error
  onPress: () => void
}

export default function ModalErrorAlert(props: Props) {
  const { error, onPress } = props
  return (
    <Modal needsToShow={error !== null} description={error.message}>
      <TouchableOpacity style={styles.modalButton} onPress={onPress}>
        <Text style={styles.modalButtonLabel}>OK</Text>
      </TouchableOpacity>
    </Modal>
  )
}
 