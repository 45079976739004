import { Dispatch } from 'redux'
import { Api } from '../../lib'
import { setTickets } from '../modules/tickets'
import { Domain } from '..'

type Data = { tickets: Array<Domain.Ticket.Entity> }

function didCallFetchGetTicketsApi(dispatch: Dispatch, data: Data) {
  dispatch(setTickets(data.tickets))
}

export function getTickets() {
  return async function(dispatch: Dispatch) {
    await Api.callApi(Api.HttpMethod.get, 'tickets', null, dispatch, didCallFetchGetTicketsApi)
  }
}
