import * as Domain from '../domain'

type CouponData = Domain.LINECoupon.CouponData | null

export function createInitialState(): CouponData {
  return null
}

export const SET_COUPON_DATA = 'coupon-data/set' as const
export const CLEAR_COUPON_DATA = 'coupon-data/clear' as const

export function setCouponData(couponData: CouponData) {
  return {
    type: SET_COUPON_DATA,
    payload: {
      couponData,
    },
  }
}

export function clearCouponData() {
  return {
    type: CLEAR_COUPON_DATA,
  }
}

type SetCouponDataAction = ReturnType<typeof setCouponData>
type ClearCouponDataAction = ReturnType<typeof clearCouponData>
type Action = SetCouponDataAction | ClearCouponDataAction

export function reducer(state = createInitialState(), action: Action) {
  switch (action.type) {
    case SET_COUPON_DATA:
      return action.payload.couponData
    case CLEAR_COUPON_DATA:
      return null
    default:
      return state
  }
}
