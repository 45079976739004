import { Dispatch } from 'redux'
import { Domain } from '../core'
import { clearError, setError } from '../core/modules/error'
import { beginCommunication, endCommunication } from '../core/modules/network'

const NETWORK_ERROR_MESSAGE = '通信ができません。通信設定及び、電波状況などをご確認ください。'
const SERVER_ERROR_MESSAGE = '通信エラーです。時間をおいて再度お試しください。'
const RELOAD_LABEL = '再読み込み'

export enum HttpMethod {
  get = 'GET',
  post = 'POST',
  patch = 'PATCH',
}

interface ApiErrorResponse {
  code: string
  message: string
}

export type DidCallApiCallback<T> = (dispatch: Dispatch, data: T, options?: Options) => void
export interface Options {
  reload?: () => void
  navigate?: () => void
}

function createError(apiErrorResponse: ApiErrorResponse) {
  if (apiErrorResponse.message) {
    return new Error(apiErrorResponse.message)
  }
  return new Error(SERVER_ERROR_MESSAGE)
}

function isJsonResponse(response: Response): boolean {
  return response.headers.get('Content-Type')?.includes('application/json') ?? false
}

export function validateOnLine(dispatch: Dispatch) {
  if (Domain.App.isOnline()) {
    return true
  }
  dispatch(setError(new Error(NETWORK_ERROR_MESSAGE)))
  return false
}

// ----- call GET/POST/PUT/DELETE Api -----
export async function callApi<T>(
  httpMethod: HttpMethod,
  apiName: string,
  body: string | null,
  dispatch: Dispatch,
  didCallApiCallback?: DidCallApiCallback<T>,
  options?: Options,
  s3path?: boolean,
) {
  const apiUrl = s3path
    ? `${process.env.REACT_APP_STATIC_RESOURCE_BASE_PATH}/${apiName}`
    : `${process.env.REACT_APP_MEGANE_API_ENDPOINT}/${apiName}`
  try {
    dispatch(beginCommunication())
    dispatch(clearError())

    const headers = {
      Authorization: Domain.Auth.getAccessToken(),
      'Content-Type': 'application/json',
    }
    const init = body
      ? {
          method: httpMethod,
          headers,
          body: body,
        }
      : {
          method: httpMethod,
          headers,
        }

    const response = await fetch(apiUrl, s3path ? {} : init)
    if (!response.ok) {
      if (!isJsonResponse(response)) {
        dispatch(setError(new Error(SERVER_ERROR_MESSAGE)))
        return
      }

      const apiErrorResponse: ApiErrorResponse = await response.json()
      if (!apiErrorResponse.code.indexOf('40')) {
        dispatch(setError(createError(apiErrorResponse)))
        return
      } else {
        dispatch(setError(new Error(SERVER_ERROR_MESSAGE)))
        return
      }
    }
    const data: T = await response.json()
    if (didCallApiCallback) {
      didCallApiCallback(dispatch, data, options)
    }
  } catch (e) {
    if (options && options.reload) {
      dispatch(setError(new Domain.RecoverableError(NETWORK_ERROR_MESSAGE, RELOAD_LABEL, options.reload)))
      return
    }

    if (!validateOnLine(dispatch)) {
      return
    }

    dispatch(setError(new Error(SERVER_ERROR_MESSAGE)))
  } finally {
    dispatch(endCommunication())
  }
}
