import * as React from 'react'
import { StyleSheet, Text, TouchableOpacity, View, ViewStyle } from 'react-native'
import { COLORS } from '../../constants'
import { useNavigate } from '../../lib/hooks'

const styles = StyleSheet.create({
  footerContainer: {
    marginTop: 100,
    marginBottom: 44,
  },
  footerElement: {
    marginHorizontal: 16,
    marginTop: 16,
    alignItems: 'center',
  },
  underLineText: {
    fontSize: 16,
    color: COLORS.LINK,
    textDecorationLine: 'underline',
  },
})

interface Props {
  style?: ViewStyle
}

export default function Footer(props: Props) {
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const navigateToTerms = useNavigate('/terms')
  const navigateToPrivacyPolicy = useNavigate('/privacy')
  const navigateToFAQ = useNavigate('/faq')

  return (
    <View style={[styles.footerContainer, props.style]}>
      <TouchableOpacity style={styles.footerElement}>
        <Text
          style={styles.underLineText}
          onPress={() => {
            navigateToFAQ()
          }}>
          よくある質問
        </Text>
      </TouchableOpacity>
      <TouchableOpacity style={styles.footerElement}>
        <Text
          style={styles.underLineText}
          onPress={() => {
            navigateToTerms()
          }}>
          利用規約
        </Text>
      </TouchableOpacity>
      <TouchableOpacity style={styles.footerElement}>
        <Text
          style={styles.underLineText}
          onPress={() => {
            navigateToPrivacyPolicy()
          }}>
          プライバシーポリシー
        </Text>
      </TouchableOpacity>
    </View>
  )
}
