import * as React from 'react'
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { COLORS } from '../../../constants'
import * as Domain from '../../../core/domain'

const styles = StyleSheet.create({
  background: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: COLORS.BACKGROUND,
    zIndex: 30,
  },
  container: {
    flex: 1,
    marginTop: 76,
    marginHorizontal: 16,
  },
  button: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    height: 50,
    marginTop: 28,
    backgroundColor: COLORS.ACCENT,
  },
  buttonTitle: {
    fontSize: 20,
    fontWeight: 'bold',
    color: COLORS.BACKGROUND,
  },
})

interface Props {
  error: Domain.RecoverableError
}

export default function ScreenEmbeddedErrorAlert(props: Props) {
  const { error } = props

  return (
    <View style={styles.background}>
      <View style={styles.container}>
        <Text>{error.message}</Text>
        <TouchableOpacity style={styles.button} onPress={error.recovery}>
          <Text style={styles.buttonTitle}>{error.label}</Text>
        </TouchableOpacity>
      </View>
    </View>
  )
}
