import * as Domain from '../domain'

type SurveyContents = Domain.LINECoupon.SurveyQuestionnaires

const initialState = {questions:[]}

export function createInitialState(): SurveyContents {
  return initialState
}

export const SET_SURVEY_CONTENTS = 'survey-contents/set' as const
export const CLEAR_SURVEY_CONTENTS = 'survey-contents/clear' as const

export function setSurveyContents(survey: SurveyContents) {
  return {
    type: SET_SURVEY_CONTENTS,
    payload: {
      survey,
    },
  }
}

export function clearSurveyContents() {
  return {
    type: CLEAR_SURVEY_CONTENTS,
  }
}

type SetSurveyContentsAction = ReturnType<typeof setSurveyContents>
type ClearSurveyContentsAction = ReturnType<typeof clearSurveyContents>
type Action = SetSurveyContentsAction | ClearSurveyContentsAction

export function reducer(state = createInitialState(), action: Action) {
  switch (action.type) {
    case SET_SURVEY_CONTENTS:
      return action.payload.survey
    case CLEAR_SURVEY_CONTENTS:
      return initialState
    default:
      return state
  }
}
