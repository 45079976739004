import { combineReducers } from 'redux'
import * as CouponContents from './couponContents'
import * as CouponData from './couponData'
import * as CustomerFeedback from './customerFeedback'
import * as ErrorModule from './error'
import * as MembersCard from './membersCard'
import * as Network from './network'
import * as SurveyContents from './surveyContents'
import * as TicketRedeemResult from './ticketRedeemResult'
import * as Tickets from './tickets'
import * as Pagination from './pagination'

export { ErrorModule, MembersCard, Network, CouponData, SurveyContents, CouponContents, TicketRedeemResult, Pagination }

export type AppState = {
  readonly error: ReturnType<typeof ErrorModule.createInitialState>
  readonly network: ReturnType<typeof Network.createInitialState>
  readonly membersCard: ReturnType<typeof MembersCard.createInitialState>
  readonly couponData: ReturnType<typeof CouponData.createInitialState>
  readonly tickets: ReturnType<typeof Tickets.createInitialState>
  readonly ticketRedeemResult: ReturnType<typeof TicketRedeemResult.createInitialState>
  readonly surveyContents: ReturnType<typeof SurveyContents.createInitialState>
  readonly couponContents: ReturnType<typeof CouponContents.createInitialState>
  readonly customerFeedback: ReturnType<typeof CustomerFeedback.createInitialState>
  readonly paginationNumber: ReturnType<typeof Pagination.createInitialState>
}

export function createInitialState(): AppState {
  return {
    error: ErrorModule.createInitialState(),
    network: Network.createInitialState(),
    membersCard: MembersCard.createInitialState(),
    couponData: CouponData.createInitialState(),
    ticketRedeemResult: TicketRedeemResult.createInitialState(),
    tickets: Tickets.createInitialState(),
    surveyContents: SurveyContents.createInitialState(),
    couponContents: CouponContents.createInitialState(),
    customerFeedback: CustomerFeedback.createInitialState(),
    paginationNumber: Pagination.createInitialState(),
  }
}

function createAppReducer() {
  return combineReducers<AppState>({
    error: ErrorModule.reducer,
    network: Network.reducer,
    membersCard: MembersCard.reducer,
    couponData: CouponData.reducer,
    ticketRedeemResult: TicketRedeemResult.reducer,
    tickets: Tickets.reducer,
    surveyContents: SurveyContents.reducer,
    couponContents: CouponContents.reducer,
    customerFeedback: CustomerFeedback.reducer,
    paginationNumber: Pagination.reducer,
  })
}

export default createAppReducer
