import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import TicketList from '../components/screens/Tickets'
import { Modules, Usecases } from '../core'
import { setPaginationNumber } from '../core/modules/pagination'

export default function TicketListContainer() {
  const tickets = useSelector((state: Modules.AppState) => state.tickets)
  const ticketRedeemResult = useSelector((state: Modules.AppState) => state.ticketRedeemResult)
  const paginationNumber = useSelector((state: Modules.AppState) => state.paginationNumber)
  const dispatch = useDispatch()
  const actions = {
    getTickets() {
      dispatch(Usecases.Tickets.getTickets())
    },
    redeemTicket(ticketId: string, incentiveType: string) {
      dispatch(Usecases.RedeemTicket.redeemTicket(ticketId, incentiveType))
    },
    clearTicketRedeemResult() {
      dispatch(Modules.TicketRedeemResult.clearTicketRedeemResult())
    },
    // eslint-disable-next-line no-shadow
    setPaginationNumber(paginationNumber: number) {
      dispatch(setPaginationNumber(paginationNumber))
    },
  }

  return (
    <TicketList
      tickets={tickets}
      ticketRedeemResult={ticketRedeemResult}
      paginationNumber={paginationNumber}
      actions={actions}
    />
  )
}
