import * as React from 'react'
import { Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { useParams } from 'react-router-dom'
import { COLORS } from '../../../constants'
import { Domain } from '../../../core'
import ValidationErrors from '../../common/ValidationErrors'
import { validateInputMinLength } from '../RegisterMembersCard/Form/FormValidation'

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  selected: {
    backgroundColor: COLORS.ACCENT_LIGHT,
    paddingVertical: 20,
  },
  giftItem: {
    fontSize: 16,
    paddingVertical: 20,
    flex: 1,
    borderBottomWidth: 1,
    borderColor: COLORS.LIGHTGRAY,
  },
  giftImage: {
    height: 135,
    width: 172,
  },
  wrapper: {
    flexDirection: 'row',
    flex: 1,
    marginHorizontal: 16,
  },
  giftImageWrapper: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  option: {
    flex: 1,
  },
  label: {
    fontSize: 16,
    textAlign: 'left',
  },
  icon: {
    width: 20,
    height: 20,
    marginRight: 19,
    borderWidth: 1,
    borderRadius: 10,
    borderColor: COLORS.LIGHTGRAY,
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconInner: {
    width: 10,
    height: 10,
    backgroundColor: COLORS.ACCENT,
    borderRadius: 5,
  },
})

const MIN_LENGTH = 1
const ERR_MESSAGE_MIN = '回答を選択してください'

interface Props {
  selectedGiftOption: null | string
  tickets: Array<Domain.Ticket.Entity>
  setSelectedGiftOption: (selectedValue: string) => void
  setIsSelectedValidation: (isValid: boolean) => void
  setSelectedGiftName: (selectedGiftName: string) => void
  getTickets: () => void
}

export default function GiftList(props: Props) {
  const { ticketNumber } = useParams<{ ticketNumber: string}>()
  const { selectedGiftOption, setSelectedGiftOption, setIsSelectedValidation, setSelectedGiftName } = props

  const [errors, setErrors] = React.useState<Array<string>>([])
  const validateAll = React.useCallback(() => {
    const errorMessages = [validateInputMinLength(selectedGiftOption, MIN_LENGTH, ERR_MESSAGE_MIN)].flat()
    setErrors(errorMessages)
    setIsSelectedValidation(errorMessages.length === 0)
  }, [selectedGiftOption, setErrors, setIsSelectedValidation])

  const isFirstRendering = React.useRef(true)
  React.useEffect(() => {
    if (!props.tickets.length) {
      props.getTickets()
      return
    }
    if (isFirstRendering.current) {
      isFirstRendering.current = false
      return
    }
    validateAll()
  }, [validateAll, props])

  // SpecialTicket交換後のTicketsにはcandidateIncentives要素がないため、交換後はバックグラウンドを空白にすることでエラーを回避する
  if (!props.tickets.length || !props.tickets[Number(ticketNumber) - 1].candidateIncentives) {
    return <></>
  }

  const giftItems = props.tickets[Number(ticketNumber) - 1].candidateIncentives.map((item, _i) => {
    const giftImage = item.imageUrl && (
      <View style={styles.giftImageWrapper}>
        <Image style={styles.giftImage} source={{ uri: item.imageUrl }} />
      </View>
    )
    const active = selectedGiftOption === item.incentiveType
    return (
      <TouchableOpacity
        style={[styles.giftItem, active && styles.selected]}
        disabled={active}
        key={item.incentiveName}
        onPress={() => {
          setSelectedGiftOption(item.incentiveType)
          setSelectedGiftName(item.incentiveName)
        }}>
        <View style={styles.option}>
          <View style={styles.wrapper}>
            <View style={styles.icon}>{active && <View style={styles.iconInner} />}</View>
            <Text style={styles.label}>{item.incentiveName}</Text>
          </View>
          {giftImage ? giftImage : undefined}
        </View>
      </TouchableOpacity>
    )
  })
  return (
    <View style={styles.container}>
      <ValidationErrors errors={errors} />
      {giftItems}
    </View>
  )
}
