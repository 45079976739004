import * as Domain from '../domain'

type CouponContents = Domain.LINECoupon.CouponContents

const initialState = { conponCode: '', couponImageUrl: '', couponItems: [] }

export function createInitialState(): CouponContents {
  return initialState
}

export const SET_COUPON_CONTENTS = 'coupon-contents/set' as const
export const CLEAR_COUPON_CONTENTS = 'coupon-contents/clear' as const

export function setCouponContents(coupon: CouponContents) {
  return {
    type: SET_COUPON_CONTENTS,
    payload: {
      coupon,
    },
  }
}

export function clearCouponContents() {
  return {
    type: CLEAR_COUPON_CONTENTS,
  }
}

type SetCouponContentsAction = ReturnType<typeof setCouponContents>
type ClearCouponContentsAction = ReturnType<typeof clearCouponContents>
type Action = SetCouponContentsAction | ClearCouponContentsAction

export function reducer(state = createInitialState(), action: Action) {
  switch (action.type) {
    case SET_COUPON_CONTENTS:
      return action.payload.coupon
    case CLEAR_COUPON_CONTENTS:
      return initialState
    default:
      return state
  }
}
