export interface RecoveryAction {
  (): void
}

export default class RecoverableError extends Error {
  label: string
  recovery: RecoveryAction

  constructor(message: string, label: string, recovery: RecoveryAction) {
    super(message)
    this.label = label
    this.recovery = recovery
  }
}
