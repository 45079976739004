import * as React from 'react'
import { ScrollView, StyleSheet, View } from 'react-native'
import { COLORS } from '../../../constants'
import { Domain } from '../../../core'
import Footer from '../../common/Footer'
import CustomerInfo from './CustometInfo'
import ShareOptionMenu from './ShareOptionMenu'

const styles = StyleSheet.create({
  border: {
    borderTopWidth: 1,
    borderColor: COLORS.LIGHTGRAY,
  },
})

interface Props {
  membersCard: Domain.MembersCard.Entity | null
  actions: {
    getMembersCard: () => void
  }
}

export default function ChooseShareOption(props: Props) {
  const {
    membersCard,
    actions: { getMembersCard },
  } = props

  React.useEffect(
    () => {
      if (!membersCard?.membersCardNo) {
        getMembersCard()
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  return (
    <View>
      <ScrollView>
        <ShareOptionMenu />
      </ScrollView>
      <View style={styles.border} />
      <CustomerInfo membersCard={membersCard} />
      <View style={styles.border} />
      <Footer />
    </View>
  )
}
