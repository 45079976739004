// Color parette from Design Guidelines
const PRIMARY = '#000000' // Text
const ACCENT = '#0276C9' // Corporate Color
const ACCENT_LIGHT = '#E5F1F9' // Corporate Color2
const BACKGROUND = '#ffffff'
const BACKGROUND_LIGHTBLUE = '#f5f5f5'
const VALIDATION_ERR = '#F9E9Ed'
const LINK = '#0E73DC'
const LINE = '#10AD00'

// Other colours used in this project
const LIGHTGRAY = '#b5b5b5' // Form Place Holder Color
const WHITE = '#FFFFFF'
const DISABLED = '#eaeaea'

export {
  PRIMARY,
  ACCENT,
  ACCENT_LIGHT,
  LIGHTGRAY,
  BACKGROUND,
  BACKGROUND_LIGHTBLUE,
  DISABLED,
  LINE,
  LINK,
  WHITE,
  VALIDATION_ERR,
}
