import * as React from 'react'
import { StyleSheet, View } from 'react-native'
import { Domain } from '../../../core'
import NormalTicket from './NormalTicket'
import SpecialTicket from './SpecialTicket'

const styles = StyleSheet.create({
  ticketContainer: {
    marginTop: 16,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
})

interface Props {
  tickets: Array<Domain.Ticket.Entity>
  paginationNumber: number | null
  handleOnPressRedeemNormalTicket: (title: string, ticketId: string, incentiveCode: string) => void
  handleOnPressConfirmGiftCode: (title: string, incentiveCode: string, expirationDate: string) => void
  handleOnPressConfirmSelectGift: (title: string) => void
}

export default function TicketList(props: Props) {
  const TICKETS_PAR_PAGE = 10
  let paginationBaseNumber = TICKETS_PAR_PAGE * (props.paginationNumber ?? 0)
  let paginationBaseTicketNumber = TICKETS_PAR_PAGE * (props.paginationNumber ?? 0) + 1
  return (
    <>
      {[...Array(2)].map((_, i) => {
        return (
          <View key={i.toString()}>
            <View style={styles.ticketContainer}>
              <NormalTicket
                ticket={props.tickets[paginationBaseNumber++]}
                ticketNumber={paginationBaseTicketNumber++}
                handleOnPressRedeemNormalTicket={props.handleOnPressRedeemNormalTicket}
                handleOnPressConfirmGiftCode={props.handleOnPressConfirmGiftCode}
              />
              <NormalTicket
                ticket={props.tickets[paginationBaseNumber++]}
                ticketNumber={paginationBaseTicketNumber++}
                handleOnPressRedeemNormalTicket={props.handleOnPressRedeemNormalTicket}
                handleOnPressConfirmGiftCode={props.handleOnPressConfirmGiftCode}
              />
            </View>
            <View style={styles.ticketContainer}>
              <NormalTicket
                ticket={props.tickets[paginationBaseNumber++]}
                ticketNumber={paginationBaseTicketNumber++}
                handleOnPressRedeemNormalTicket={props.handleOnPressRedeemNormalTicket}
                handleOnPressConfirmGiftCode={props.handleOnPressConfirmGiftCode}
              />
              <NormalTicket
                ticket={props.tickets[paginationBaseNumber++]}
                ticketNumber={paginationBaseTicketNumber++}
                handleOnPressRedeemNormalTicket={props.handleOnPressRedeemNormalTicket}
                handleOnPressConfirmGiftCode={props.handleOnPressConfirmGiftCode}
              />
            </View>
            <View style={styles.ticketContainer}>
              <SpecialTicket
                ticket={props.tickets[paginationBaseNumber++]}
                ticketNumber={paginationBaseTicketNumber++}
                handleOnPressConfirmGiftCode={props.handleOnPressConfirmGiftCode}
                handleOnPressConfirmSelectGift={props.handleOnPressConfirmSelectGift}
              />
            </View>
          </View>
        )
      })}
    </>
  )
}
