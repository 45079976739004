import { useHistory } from 'react-router-dom'

export function useNavigate(path: string, state?: {}) {
  const history = useHistory()
  return function navigate() {
    history.push({
      pathname: path,
      state,
    })
  }
}

export function useNavigateReplace(path: string, state?: {}) {
  const history = useHistory()
  return function navigate() {
    history.replace({
      pathname: path,
      state,
    })
  }
}
