import React from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import NeedsSignIn from './components/NeedsSignIn'
import ApiErrorAlert from './containers/ApiErrorAlert'
import LoadingPanel from './containers/LoadingPanel'
import RouteSelection from './containers/RouteSelection'
import { Domain, Store } from './core'
import { needsToLineLogin } from './Routes'

function App() {
  const [signInFinished, setSignInFinished] = React.useState(false)
  const [signInFailed, setSignInFailed] = React.useState(false)

  React.useEffect(() => {
    Domain.Auth.init().then((value: boolean) => {
      if (value && needsToLineLogin) {
        Domain.Auth.signIn()
          .then(() => {
            setSignInFailed(false)
          })
          .catch(() => {
            setSignInFailed(true)
          })
          .finally(() => {
            setSignInFinished(true)
          })
      }
    })
  }, [])

  if (!signInFinished && needsToLineLogin) {
    return <></>
  } else if (signInFailed && needsToLineLogin) {
    return <NeedsSignIn />
  } else {
    return (
      <Provider store={Store.store}>
        <Router>
          <LoadingPanel />
          <ApiErrorAlert />
          <RouteSelection />
        </Router>
      </Provider>
    )
  }
}

export default App
