import * as React from 'react'
import { Domain } from '../core'
import { CardRegisteredUserRoutes, CardUnregisteredUserRoutes, needsToLineLogin } from '../Routes'
interface Props {
  membersCard: Domain.MembersCard.Entity | null
  actions: {
    getMembersCard: () => void
  }
}

export default function RouteSelection(props: Props) {
  const {
    membersCard,
    actions: { getMembersCard },
  } = props

  React.useEffect(() => {
    if (!membersCard && needsToLineLogin) {
      getMembersCard()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!membersCard && needsToLineLogin) {
    return <></>
  }

  return membersCard?.membersCardNo && !membersCard.registrationComplete ? (
    <CardRegisteredUserRoutes />
  ) : (
    <CardUnregisteredUserRoutes />
  )
}
