import * as React from 'react'
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { COLORS, FONTS } from '../../../constants'

const styles = StyleSheet.create({
  paginationContainer: {
    marginTop: 16,
    flexDirection: 'row',
    justifyContent: 'center',
  },
  paginationElement: {
    marginHorizontal: 6,
    borderWidth: 1,
    borderColor: COLORS.LIGHTGRAY,
    height: 48,
    width: 48,
    alignItems: 'center',
    justifyContent: 'center',
  },
  paginationCurrentElement: {
    marginHorizontal: 6,
    borderWidth: 1,
    borderColor: COLORS.LIGHTGRAY,
    backgroundColor: COLORS.ACCENT_LIGHT,
    height: 48,
    width: 48,
    alignItems: 'center',
    justifyContent: 'center',
  },
  paginationLabel: {
    fontSize: 20,
    color: COLORS.PRIMARY,
  },
  currentTextContainer: {
    marginTop: 16,
    justifyContent: 'center',
    alignItems: 'center',
  },
  currentTextLabel: {
    fontSize: 16,
    fontFamily: FONTS.MAIN_FONT,
  },
})

interface Props {
  paginationNumber: number | null
  setPaginationNumber: (paginationNumber: number) => void
}

const PAGINATION_MAX_COUNT = 5
export default function Pagination(props: Props) {
  return (
    <View>
      <View style={styles.paginationContainer}>
        {[...Array(5)].map((_, i) => {
          return (
            <TouchableOpacity
              key={i.toString()}
              style={props.paginationNumber === i ? styles.paginationCurrentElement : styles.paginationElement}
              onPress={() => props.setPaginationNumber(i)}>
              <Text style={styles.paginationLabel}>{i + 1}</Text>
            </TouchableOpacity>
          )
        })}
      </View>
      <View style={styles.currentTextContainer}>
        <Text style={styles.currentTextLabel}>
          {props.paginationNumber ? props.paginationNumber + 1 : 1}／{PAGINATION_MAX_COUNT}ページを表示しています
        </Text>
      </View>
    </View>
  )
}
