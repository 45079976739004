import * as React from 'react'
import { ScrollView, View } from 'react-native'
import { Domain } from '../../../core'
import Footer from '../../common/Footer'
import Form from './Form'

interface Props {
  couponContents: Domain.LINECoupon.CouponContents
  surveyContents: Domain.LINECoupon.SurveyQuestionnaires
  actions: {
    createCoupon: (request: Domain.LINECoupon.SurveyFeedBack) => void
    getSurveyContents: () => void
    getCouponContents: () => void
  }
}

export default function SendLINECoupon(props: Props) {
  return (
    <View>
      <ScrollView>
        <Form couponContents={props.couponContents} surveyContents={props.surveyContents} actions={props.actions} />
      </ScrollView>
      <Footer />
    </View>
  )
}
