/**
 * network module
 *  The state is count of network connecting.
 *
 *  Make sure to dispatch actions of END_COMMUNICATION when all of network
 *  communications are ended.
 *
 *  1. dispatching BEGIN_COMMUNICATION -> 1
 *  2. dispatching BEGIN_COMMUNICATION -> 2
 *  3. dispatching END_COMMUNICATION -> 1
 *  4. dispatching BEGIN_COMMUNICATION -> 2
 *  5. dispatching END_COMMUNICATION -> 1
 *  6. dispatching END_COMMUNICATION -> 0
 *
 *  See ../selectors/network.ts to be mapped this state for View tier.
 *
 * */

export function createInitialState() {
  return 0
}

export const BEGIN_COMMUNICATION = 'network/begin-communication' as const
export const END_COMMUNICATION = 'network/end-communication' as const

export function beginCommunication() {
  return {
    type: BEGIN_COMMUNICATION,
  }
}
export function endCommunication() {
  return {
    type: END_COMMUNICATION,
  }
}

type BeginCommunicationAction = ReturnType<typeof beginCommunication>
type EndCommunicationAction = ReturnType<typeof endCommunication>
export type Action = BeginCommunicationAction | EndCommunicationAction

export function reducer(state = createInitialState(), action: Action) {
  switch (action.type) {
    case BEGIN_COMMUNICATION:
      return state + 1
    case END_COMMUNICATION:
      return state - 1
    default:
      return state
  }
}
