import * as React from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { FONTS } from '../../../../constants'
import { Domain } from '../../../../core'
import { useNavigate } from '../../../../lib/hooks'
import CouponContents from './CouponContents'
import FirstSurvey from './FirstSurvey'
import SecondSurvey from './SecondSurvey'
import SendLINECouponButton from './SendLINECouponButton'
import ThirdSurvey from './ThirdSurvey'

const styles = StyleSheet.create({
  headline: {
    fontSize: 20,
    fontFamily: FONTS.MAIN_FONT,
    marginTop: 28,
    marginHorizontal: 16,
    marginBottom: 0,
    textAlign: 'center',
  },
  description: {
    marginTop: 28,
    marginHorizontal: 16,
    fontSize: 16,
    fontFamily: FONTS.MAIN_FONT,
    textAlign: 'center',
  },
  subHeadline: {
    marginTop: 28,
    textAlign: 'center',
  },
  subHeadlineText: {
    marginHorizontal: 16,
    fontSize: 16,
    fontFamily: FONTS.MAIN_FONT,
    textAlign: 'center',
  },
  button: {
    marginTop: 28,
  },
})

interface Props {
  couponContents: Domain.LINECoupon.CouponContents
  surveyContents: Domain.LINECoupon.SurveyQuestionnaires
  actions: {
    createCoupon: (request: Domain.LINECoupon.SurveyFeedBack) => void
    getSurveyContents: () => void
    getCouponContents: () => void
  }
}

export default function Form(props: Props) {
  const {
    couponContents,
    surveyContents,
    actions: { getSurveyContents, getCouponContents },
  } = props

  React.useEffect(() => {
    getSurveyContents()
    getCouponContents()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const firstSurveyQuestion: string = surveyContents.questions[0] ? surveyContents.questions[0].questionName : ''
  const firstSurveyAnswerOptions: Array<string> | undefined = surveyContents.questions[0]
    ? surveyContents.questions[0].answerOption
    : undefined

  const [firstSurveyAnswer, setFirstSurveyAnswer] = React.useState('')
  const [firstSurveyAnswerValidation, setFirstSurveyAnswerValidation] = React.useState(false)

  const secondSurveyQuestion: string = surveyContents.questions[1] ? surveyContents.questions[1].questionName : ''
  const secondSurveyAnswerOptions: Array<string> | undefined = surveyContents.questions[1]
    ? surveyContents.questions[1].answerOption
    : undefined

  const [secondSurveyAnswer, setSecondSurveyAnswer] = React.useState('')
  const [secondSurveyAnswerValidation, setSecondSurveyAnswerValidation] = React.useState(false)

  const thirdSurveyQuestion = surveyContents.questions[2] ? surveyContents.questions[2].questionName : ''
  const [thirdSurveyAnswer, setThirdSurveyAnswer] = React.useState('')

  const buttonEnabled = firstSurveyAnswerValidation && secondSurveyAnswerValidation

  const navigate = useNavigate('/share-target-picker')
  const handleOnPress = () => {
    const feedback = {
      couponCode: couponContents.conponCode,
      questionnaires: [
        {
          question: firstSurveyQuestion,
          answer: firstSurveyAnswer,
        },
        {
          question: secondSurveyQuestion,
          answer: secondSurveyAnswer,
        },
      ],
    }
    if (thirdSurveyAnswer) {
      feedback.questionnaires.push({
        question: thirdSurveyQuestion,
        answer: thirdSurveyAnswer,
      })
    }

    props.actions.createCoupon(feedback)
    navigate()
  }

  return (
    <View>
      <View>
        <Text style={styles.headline}>LINEでクーポンを送る</Text>
        <Text style={styles.description}>LINEでつながっているご家族・ご友人に送付できます</Text>
        <View>
          <CouponContents couponContents={couponContents} />
        </View>
      </View>
      <View style={styles.subHeadline}>
        <Text style={styles.subHeadlineText}>店舗やスタッフについて</Text>
        <Text style={styles.subHeadlineText}>友だちにお伝えください！</Text>
        <View>
          <FirstSurvey
            firstSurveyQuestion={firstSurveyQuestion}
            firstSurveyAnswer={firstSurveyAnswer}
            firstSurveyAnswerOptions={firstSurveyAnswerOptions}
            setFirstSurveyAnswer={setFirstSurveyAnswer}
            setFirstSurveyAnswerValidation={setFirstSurveyAnswerValidation}
          />
          <SecondSurvey
            secondSurveyQuestion={secondSurveyQuestion}
            secondSurveyAnswer={secondSurveyAnswer}
            secondSurveyAnswerOptions={secondSurveyAnswerOptions}
            setSecondSurveyAnswer={setSecondSurveyAnswer}
            setSecondSurveyAnswerValidation={setSecondSurveyAnswerValidation}
          />
          <ThirdSurvey
            thirdSurveyQuestion={thirdSurveyQuestion}
            thirdSurveyAnswer={thirdSurveyAnswer}
            setThirdSurveyAnswer={setThirdSurveyAnswer}
          />
        </View>
      </View>
      <View>
        <SendLINECouponButton createCoupon={handleOnPress} style={styles.button} disabled={!buttonEnabled} />
      </View>
    </View>
  )
}
