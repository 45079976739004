import * as React from 'react'
import { Image, ImageStyle, StyleProp, StyleSheet, Text, View, ViewStyle } from 'react-native'
import { COLORS } from '../../../constants'

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 16,
    alignItems: 'center',
  },
  stepLabelContainer: {
    color: COLORS.WHITE,
    backgroundColor: COLORS.ACCENT,
    width: 64,
    height: 24,
    alignItems: 'center',
    justifyContent: 'center',
  },
  stepLabel: {
    color: COLORS.WHITE,
  },
  title: {
    marginTop: 12,
    fontSize: 20,
    textAlign: 'center',
  },
  image: {
    marginTop: 20,
  },
  subText: {
    marginTop: 20,
    fontSize: 16,
    textAlign: 'center',
    marginBottom: 8,
  },
})

interface Props {
  stepLabel: string
  title: string
  imageSource: string
  subText: string
  imageStyle: ViewStyle
}

export default function Step(props: Props) {
  return (
    <View>
      <View style={styles.container}>
        <View style={styles.stepLabelContainer}>
          <Text style={styles.stepLabel}>{props.stepLabel}</Text>
        </View>
        <Text style={styles.title}>{props.title}</Text>
        <Image style={[styles.image, props.imageStyle] as StyleProp<ImageStyle>} source={{ uri: props.imageSource }} />
        <Text style={styles.subText}>{props.subText}</Text>
      </View>
    </View>
  )
}
