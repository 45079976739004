import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import RegisterMembersCard from '../components/screens/RegisterMembersCard'
import { Domain, Modules, Usecases } from '../core'

export default function RegisterMembersCardContainer() {
  const membersCard = useSelector((state: Modules.AppState) => state.membersCard)

  const dispatch = useDispatch()
  const actions = {
    registerMember(memberData: Domain.MembersCard.Entity) {
      dispatch(Usecases.MembersCardRegist.registerMember(memberData))
    },
  }

  return <RegisterMembersCard membersCard={membersCard} actions={actions} />
}
