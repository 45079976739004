import * as Domain from '../domain'

type Tickets = Array<Domain.Ticket.Entity>
type Ticket = Domain.Ticket.Entity

export function createInitialState(): Tickets {
  return []
}

export const SET_TICKETS = 'tickets/set' as const
export const SET_TICKET = 'ticket/set' as const
export const CLEAR_TICKETS = 'tickets/clear' as const

export function setTickets(tickets: Tickets) {
  return {
    type: SET_TICKETS,
    payload: {
      tickets,
    },
  }
}

export function setTicket(ticket: Ticket) {
  return {
    type: SET_TICKET,
    payload: {
      ticket,
    },
  }
}

export function clearTickets() {
  return {
    type: CLEAR_TICKETS,
  }
}

type SetTicketsAction = ReturnType<typeof setTickets>
type SetTicketAction = ReturnType<typeof setTicket>
type ClearTicketsAction = ReturnType<typeof clearTickets>
type Action = SetTicketsAction | SetTicketAction | ClearTicketsAction

export function reducer(state = createInitialState(), action: Action) {
  switch (action.type) {
    case SET_TICKETS:
      return action.payload.tickets
    case SET_TICKET:
      return state.map(ticket => {
        return ticket.ticketId === action.payload.ticket.ticketId ? action.payload.ticket : ticket
      })
    case CLEAR_TICKETS:
      return []
    default:
      return state
  }
}
