import * as React from 'react'
import { Route, Switch } from 'react-router-dom'
import FAQ from './components/screens/FAQ'
import LP from './components/screens/LP'
import PrivacyPolicy from './components/screens/PrivacyPolicy'
import Terms from './components/screens/Terms'
import ChooseShareOption from './containers/ChooseShareOption'
import RegisterMembersCard from './containers/RegisterMembersCard'
import SendLINECoupon from './containers/SendLINECoupon'
import ShareTargetPicker from './containers/ShareTargetPicker'
import SpecialGift from './containers/SpecialGift'
import Tickets from './containers/Tickets'

export const needsToLineLogin = (() => {
  const ignoreToLineLoginPathList = ['/faq', '/privacy', '/terms', '/lp']
  const pathname = decodeURIComponent(window.location.pathname)
  return !ignoreToLineLoginPathList.includes(pathname)
})()

// Accessible without members card being registered
export const CardUnregisteredUserRoutes = () => {
  return (
    <Switch>
      <Route path="/register" component={RegisterMembersCard} />
      <Route path="/privacy" component={PrivacyPolicy} />
      <Route path="/terms" component={Terms} />
      <Route path="/faq" component={FAQ} />
      <Route path="/lp" component={LP} />
      <Route path="/" component={RegisterMembersCard} />
    </Switch>
  )
}

// Accessible after memberscard registeration
export const CardRegisteredUserRoutes = () => {
  return (
    <Switch>
      <Route path="/share-option" component={ChooseShareOption} />
      <Route path="/send-line-coupon" component={SendLINECoupon} />
      <Route path="/share-target-picker" component={ShareTargetPicker} />
      <Route exact path="/tickets" component={Tickets} />
      <Route path="/tickets/:ticketNumber" component={SpecialGift} />
      <Route path="/privacy" component={PrivacyPolicy} />
      <Route path="/terms" component={Terms} />
      <Route path="/faq" component={FAQ} />
      <Route path="/lp" component={LP} />
      <Route path="/" component={ChooseShareOption} />
    </Switch>
  )
}
