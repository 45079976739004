import * as React from 'react'
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { COLORS, FONTS } from '../../../constants'
import { Domain } from '../../../core'
import Footer from '../../common/Footer'
import Modal from '../../common/Modal'
import headlineSpecialTicketStyles from './headlineSpecialTicket.module.css'
import Pagination from './Pagination'
import TicketDescription from './TicketDescription'
import TicketList from './TicketList'
import TicketTotal from './TicketTotal'
import UntilSpecialTicket from './UntilSpecialTicket'

const styles = StyleSheet.create({
  headline: {
    fontSize: 20,
    fontFamily: FONTS.MAIN_FONT,
    marginTop: 28,
    textAlign: 'center',
  },
  headlineSpecialTicketContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  headlineSpecialTicket: {
    fontSize: 20,
    fontFamily: FONTS.MAIN_FONT,
    marginTop: 28,
    textAlign: 'center',
  },
  label: {
    fontSize: 16,
    fontFamily: FONTS.MAIN_FONT,
    marginTop: 12,
    textAlign: 'center',
  },
  border: {
    marginTop: 20,
    borderTopWidth: 1,
    borderColor: COLORS.LIGHTGRAY,
  },
  modalButtons: {
    flex: 1,
    flexDirection: 'row',
    marginVertical: 36,
    marginHorizontal: 32,
  },
  modalButtonRight: {
    flex: 1,
    textAlign: 'right',
  },
  modalButtonLeft: {
    flex: 1,
  },
  modalButtonLabel: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  modalButton: {
    justifyContent: 'center',
    alignItems: 'center',
    marginVertical: 36,
  },
})

interface Props {
  tickets: Array<Domain.Ticket.Entity>
  ticketRedeemResult: Domain.Ticket.Entity | null
  paginationNumber: number | null
  actions: {
    getTickets: () => void
    redeemTicket: (ticketId: string, incentiveType: string) => void
    clearTicketRedeemResult: () => void
    setPaginationNumber: (paginationNumber: number) => void
  }
}

export default function Tickets(props: Props) {
  const {
    tickets,
    ticketRedeemResult,
    // 表示番号ではなく配列のIndexとして利用
    paginationNumber,
    actions: { getTickets, redeemTicket, clearTicketRedeemResult, setPaginationNumber },
  } = props

  const [needsToShowRedeemNormalTicket, setNeedsToShowRedeemNormalTicket] = React.useState(false)
  const [needsToShowConfirmGiftCode, setNeedsToShowConfirmGiftCode] = React.useState(false)
  const [needsToShowConfirmSelectGift, setNeedsToShowConfirmSelectGift] = React.useState(false)

  const [redeemTicketId, setRedeemTicketId] = React.useState('')

  const [ticketTitle, setTicketTitle] = React.useState('')
  const [ticketIncentiveCode, setTicketIncentiveCode] = React.useState('')
  const [ticketExpirationDate, setTicketExpirationDate] = React.useState('')
  const [ticketIncentiveType, setTicketIncentiveType] = React.useState('')

  const MAX_TICKETS_COUNT = 50
  const TICKETS_PAR_PAGE = 10
  const SPECIAL_TICKET_PERIOD = 5

  React.useEffect(() => {
    getTickets()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    // チケット0枚:ページネーション0、チケット1-10枚:ページネーション0、、チケット11-20枚:ページネーション1 ... になるよう調整
    const adjustpagination = tickets.length === 0 ? 0 : tickets.length - 1
    // チケット交換後に再度ページネーションが実行されないように分岐を追加
    if (tickets.length !== 0 && paginationNumber === null) {
      setPaginationNumber(Math.floor(adjustpagination / TICKETS_PAR_PAGE))
    }
  }, [paginationNumber, setPaginationNumber, tickets])

  const handleOnPressRedeemNormalTicket = (title: string, ticketId: string, incentiveType: string) => {
    setNeedsToShowRedeemNormalTicket(true)
    setRedeemTicketId(ticketId)
    setTicketTitle(title)
    setTicketIncentiveType(incentiveType)
  }
  const onPressRedeemNormalTicketOK = () => {
    setNeedsToShowRedeemNormalTicket(false)
    redeemTicket(redeemTicketId, ticketIncentiveType)
  }
  const onPressRedeemNormalTicketCancel = () => setNeedsToShowRedeemNormalTicket(false)

  const handleOnPressConfirmGiftCode = (title: string, incentiveCode: string, expirationDate: string) => {
    setNeedsToShowConfirmGiftCode(true)
    setTicketTitle(title)
    setTicketIncentiveCode(incentiveCode)
    setTicketExpirationDate(expirationDate)
  }
  const onPressConfirmGiftCodeBack = () => setNeedsToShowConfirmGiftCode(false)

  const handleOnPressConfirmSelectGift = (title: string) => {
    setTicketTitle(title)
    setNeedsToShowConfirmSelectGift(true)
  }
  const onPressConfirmSelectGiftBack = () => setNeedsToShowConfirmSelectGift(false)

  return (
    <View>
      <Modal
        needsToShow={!!ticketRedeemResult && !Object.keys(ticketRedeemResult).length}
        title={'ギフトを\n準備しています'}
        description={'チケットと交換できるギフトを準備しています。暫くお待ちください。'}>
        <TouchableOpacity style={styles.modalButton} onPress={clearTicketRedeemResult}>
          <Text style={styles.modalButtonLabel}>戻る</Text>
        </TouchableOpacity>
      </Modal>
      <Modal
        needsToShow={!!ticketRedeemResult && ticketRedeemResult?.isUsed}
        title={`${ticketTitle.replace(' ', '\n')}と\n交換しました`}>
        <TouchableOpacity style={styles.modalButton} onPress={clearTicketRedeemResult}>
          <Text style={styles.modalButtonLabel}>戻る</Text>
        </TouchableOpacity>
      </Modal>
      <Modal needsToShow={needsToShowRedeemNormalTicket} title={ticketTitle} description={'上記の商品と交換しますか？'}>
        <View style={styles.modalButtons}>
          <TouchableOpacity onPress={onPressRedeemNormalTicketCancel} style={styles.modalButtonLeft}>
            <Text style={styles.modalButtonLabel}>交換しない</Text>
          </TouchableOpacity>
          <TouchableOpacity onPress={() => onPressRedeemNormalTicketOK()} style={styles.modalButtonRight}>
            <Text style={styles.modalButtonLabel}>交換する</Text>
          </TouchableOpacity>
        </View>
      </Modal>
      <Modal
        needsToShow={needsToShowConfirmGiftCode}
        title={ticketTitle}
        description={`${ticketIncentiveCode}\n使用期限： ${ticketExpirationDate}`}>
        <TouchableOpacity style={styles.modalButton} onPress={onPressConfirmGiftCodeBack}>
          <Text style={styles.modalButtonLabel}>戻る</Text>
        </TouchableOpacity>
      </Modal>
      <Modal needsToShow={needsToShowConfirmSelectGift} title={`${ticketTitle.replace(' ', '\n')}と\n交換しました`}>
        <TouchableOpacity style={styles.modalButton} onPress={onPressConfirmSelectGiftBack}>
          <Text style={styles.modalButtonLabel}>戻る</Text>
        </TouchableOpacity>
      </Modal>
      <View>
        <Text style={styles.headline}>チケット情報を確認</Text>
        <TicketTotal ticketTotal={tickets.length} />
        <View style={styles.border} />
        <View style={styles.headlineSpecialTicketContainer}>
          <div className={headlineSpecialTicketStyles.headlineSpecialTicket}>
            <Text style={styles.headlineSpecialTicket}>【特別な豪華ギフト】</Text>
          </div>
        </View>
        <Text style={styles.label}>のお受け取りまで</Text>
        <UntilSpecialTicket
          untilSpecialTicket={
            tickets.length < MAX_TICKETS_COUNT ? SPECIAL_TICKET_PERIOD - (tickets.length % SPECIAL_TICKET_PERIOD) : 0
          }
        />
        <TicketDescription />
        <TicketList
          tickets={tickets}
          paginationNumber={paginationNumber}
          handleOnPressRedeemNormalTicket={handleOnPressRedeemNormalTicket}
          handleOnPressConfirmGiftCode={handleOnPressConfirmGiftCode}
          handleOnPressConfirmSelectGift={handleOnPressConfirmSelectGift}
        />
        <Pagination paginationNumber={paginationNumber} setPaginationNumber={setPaginationNumber} />
        <Footer />
      </View>
    </View>
  )
}
