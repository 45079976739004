import * as React from 'react'
import { StyleSheet, Text, TouchableOpacity } from 'react-native'
import { COLORS } from '../../../constants'

const styles = StyleSheet.create({
  container: {
    height: 52,
    marginHorizontal: 16,
    marginTop: 44,
    alignItems: 'center',
    backgroundColor: COLORS.ACCENT,
    color: COLORS.BACKGROUND,
  },
  button: {
    margin: 12,
    fontSize: 20,
    color: COLORS.BACKGROUND,
    backgroundColor: COLORS.ACCENT,
  },
})

interface Props {
  buttonText: string
  onPress: () => void
}

export default function Button(props: Props) {
  return (
    <TouchableOpacity onPress={props.onPress} style={styles.container}>
      <Text style={styles.button}>{props.buttonText} ＞</Text>
    </TouchableOpacity>
  )
}
