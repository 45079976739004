import * as React from 'react'
import { Linking, ScrollView, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { Domain } from '../../../core'
import Footer from '../../common/Footer'
import Modal from '../../common/Modal'
import Form from './Form'

interface Props {
  membersCard: Domain.MembersCard.Entity | null
  actions: {
    registerMember: (request: Domain.MembersCard.Entity) => void
  }
}

const styles = StyleSheet.create({
  modalButton: {
    justifyContent: 'center',
    alignItems: 'center',
    marginVertical: 36,
  },
  modalButtonLabel: {
    fontSize: 16,
  },
})

export default function RegisterMembersCard(props: Props) {
  const SHARE_OPTION = `${process.env.REACT_APP_BASE_PATH}/share-option`
  const OpneUrl = (url: string) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    Linking.openURL(url, '_self')
  }
  const onPress = () => {
    OpneUrl(SHARE_OPTION)
  }

  // iOS14で発生するバックボタンが有効になってしまう不具合の対処
  React.useEffect(()=>{
    window.addEventListener("pageshow", function(event){
      if (event.persisted) {
        // 前のページのキャッシュが残ってしまう場合でも強制的に次のページへ遷移させる
        OpneUrl(SHARE_OPTION)
      }
    });
  })

  return (
    <View>
      <Modal
        needsToShow={!!props.membersCard?.membersCardNo}
        title={'メンバーズカード情報を連携しました'}
        description={'アイケアアンバサダー\nが使えるようになりました'}>
        <TouchableOpacity style={styles.modalButton} onPress={onPress}>
          <Text style={styles.modalButtonLabel}>OK</Text>
        </TouchableOpacity>
      </Modal>
      <ScrollView>
        <Form membersCard={props.membersCard?.membersCardNo} registerMember={props.actions.registerMember} />
      </ScrollView>
      <Footer />
    </View>
  )
}
