import * as EmailValidator from 'email-validator'

export function validateInputMinLength(value: string | null, length: number, errorMessage: string): Array<string> {
  if (!value || value.length < length) {
    return [errorMessage]
  }
  return []
}

export function validateInputMaxLength(value: string, length: number, errorMessage: string): Array<string> {
  if (value.length > length) {
    return [errorMessage]
  }
  return []
}

export function validateRegex(value: string, regex: string, errorMessage: string): Array<string> {
  if (!value.match(regex)) {
    return [errorMessage]
  }
  return []
}

export function validateEmail(email: string, errorMessage: string): Array<string> {
  if (!EmailValidator.validate(email)) {
    return [errorMessage]
  }
  return []
}
