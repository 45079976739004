import * as React from 'react'
import { Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { COLORS, FONTS } from '../../../constants'
import { Domain } from '../../../core'
import { useNavigate } from '../../../lib/hooks'

const styles = StyleSheet.create({
  nonTicketContainer: {
    justifyContent: 'center'
  },
  container: {
    borderWidth: 1,
    borderColor: COLORS.LIGHTGRAY,
    height: 190,
    width: 288,
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  ticketImage: {
    marginTop: 8,
    height: 60,
    width: 134,
  },
  ticketStatusContainer: {
    marginTop: 12,
    alignItems: 'center',
  },
  ticketStatusLabel: {
    fontSize: 16,
    fontFamily: FONTS.MAIN_FONT,
  },
  expiredRectangle: {
    marginTop: 16,
    height: 44,
    width: 286,
    backgroundColor: COLORS.LIGHTGRAY,
    alignItems: 'center',
    justifyContent: 'center',
  },
  confirmationRectangle: {
    marginTop: 16,
    height: 44,
    width: 286,
    backgroundColor: COLORS.ACCENT_LIGHT,
    alignItems: 'center',
    justifyContent: 'center',
  },
  confirmationLabel: {
    fontSize: 20,
    fontFamily: FONTS.MAIN_FONT,
  },
  exchangeRectangle: {
    marginTop: 16,
    height: 44,
    width: 286,
    backgroundColor: COLORS.ACCENT,
    alignItems: 'center',
    justifyContent: 'center',
  },
  exchangeLabel: {
    fontSize: 20,
    fontFamily: FONTS.MAIN_FONT,
    color: COLORS.WHITE,
  },
  emptyTicket: {
    fontSize: 56,
    color: COLORS.LIGHTGRAY,
  },
})

interface Props {
  ticket: Domain.Ticket.Entity
  ticketNumber: number
  handleOnPressConfirmGiftCode: (title: string, incentiveCode: string, expirationDate: string) => void
  handleOnPressConfirmSelectGift: (title: string) => void
}

export default function SpecialTicket(props: Props) {
  const navigate = useNavigate(`/tickets/${props.ticketNumber}`)
  const onPress = () => {
    navigate()
  }

  if (!props.ticket) {
    return (
      <View style={[styles.container, styles.nonTicketContainer]}>
        <Text style={styles.emptyTicket}>{props.ticketNumber}</Text>
      </View>
    )
  } else if (!props.ticket.isEnable) {
    return (
      <View style={styles.container}>
        <Image source={require('../../../assets/images/ExpiredTicket.png')} style={styles.ticketImage} />
        <View style={styles.ticketStatusContainer}>
          <Text style={styles.ticketStatusLabel}>チケットを</Text>
          <Text style={styles.ticketStatusLabel}>失効しました</Text>
        </View>
        <View style={styles.expiredRectangle} />
      </View>
    )
  } else {
    return (
      <>
        {props.ticket.isUsed ? (
          <View style={styles.container}>
            <Image source={require('../../../assets/images/SpecialTicketDone.png')} style={styles.ticketImage} />
            <View style={styles.ticketStatusContainer}>
              <Text style={styles.ticketStatusLabel}>チケットを</Text>
              <Text style={styles.ticketStatusLabel}>交換しました</Text>
            </View>
            {props.ticket.incentive.incentiveType === 'SelectGift' ? (
              <TouchableOpacity
                style={styles.confirmationRectangle}
                onPress={() => props.handleOnPressConfirmSelectGift(props.ticket.incentive.incentiveName)}>
                <Text style={styles.confirmationLabel}>確認する</Text>
              </TouchableOpacity>
            ) : (
              <TouchableOpacity
                style={styles.confirmationRectangle}
                onPress={() =>
                  props.handleOnPressConfirmGiftCode(
                    props.ticket.incentive.incentiveName.replace(' ', '\n'),
                    props.ticket.incentive.incentiveCode,
                    props.ticket.incentive.expirationDate,
                  )
                }>
                <Text style={styles.confirmationLabel}>確認する</Text>
              </TouchableOpacity>
            )}
          </View>
        ) : (
          <View style={styles.container}>
            <Image source={require('../../../assets/images/SpecialTicket.png')} style={styles.ticketImage} />
            <View style={styles.ticketStatusContainer}>
              <Text style={styles.ticketStatusLabel}>引き換え期限：</Text>
              <Text style={styles.ticketStatusLabel}>{props.ticket.exchangeDeadline}</Text>
            </View>
            <TouchableOpacity style={styles.exchangeRectangle} onPress={onPress}>
              <Text style={styles.exchangeLabel}>交換する</Text>
            </TouchableOpacity>
          </View>
        )}
      </>
    )
  }
}
