import * as React from 'react'
import { StyleSheet, Text, TextInput, View } from 'react-native'
import { COLORS, FONTS } from '../../../../constants'
import ValidationErrors from '../../../common/ValidationErrors'
import { validateInputMinLength, validateRegex } from './FormValidation'

const styles = StyleSheet.create({
  label: {
    marginLeft: 8,
    fontSize: 16,
    fontFamily: FONTS.MAIN_FONT,
  },
  optionalLabel: {
    fontSize: 16,
    minWidth: 42,
    textAlign: 'center',
    fontFamily: FONTS.MAIN_FONT,
    color: COLORS.BACKGROUND,
    backgroundColor: COLORS.LIGHTGRAY,
    padding: 4,
  },
  labelWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    marginHorizontal: 16,
  },
  input: {
    height: 48,
    margin: 12,
    marginHorizontal: 16,
    borderWidth: 1,
    padding: 8,
    fontSize: 16,
    textAlign: 'left',
    letterSpacing: 0.5,
    width: 114,
  },
  topSpacing: {
    marginTop: 28,
  },
})

// ValidationRule: Optional, Number, 5 digits
const REGEX = '^[0-9]*$'
const ERR_MESSAGE_MATCHER = 'スタッフコードは数字で入力してください'
const MIN_LENGTH = 5
const ERR_MESSAGE_LENGTH = 'スタッフコードは' + MIN_LENGTH + '桁で入力してください'

interface Props {
  // StaffCode
  staffCode: string
  setStaffCode: (StaffCode: string) => void
  setStaffCodeValidation: (isValid: boolean) => void
}

export default function StaffCode(props: Props) {
  const { staffCode: value, setStaffCode, setStaffCodeValidation: setValid } = props

  const [errors, setErrors] = React.useState<Array<string>>([])
  const validateAll = React.useCallback(() => {
    const errorMessages = [
      validateRegex(value, REGEX, ERR_MESSAGE_MATCHER),
      validateInputMinLength(value, MIN_LENGTH, ERR_MESSAGE_LENGTH),
    ].flat()
    setErrors(value.length === 0 ? [] : errorMessages)
    setValid(errorMessages.length === 0 || value.length === 0)
  }, [value, setErrors, setValid])

  const onChangeText = (newValue: string) => {
    setStaffCode(newValue)
  }
  const isFirstRendering = React.useRef(true)
  React.useEffect(() => {
    if (isFirstRendering.current) {
      isFirstRendering.current = false
      return
    }
    validateAll()
  }, [validateAll])

  return (
    <View style={styles.topSpacing}>
      <View style={styles.labelWrapper}>
        <Text style={styles.optionalLabel}>任意</Text>
        <Text style={styles.label}>店舗のスタッフコードを入力してください（検査や接客が良かったスタッフがいる場合のみ）</Text>
      </View>
      <ValidationErrors errors={errors} />
      <TextInput
        onChangeText={onChangeText}
        value={value}
        style={styles.input}
        autoCapitalize="none"
        autoCorrect={false}
        clearButtonMode="unless-editing"
        keyboardType="phone-pad"
        textContentType="none"
        maxLength={5} // Validation: Max Length of 5
        placeholder="12345"
        placeholderTextColor={COLORS.LIGHTGRAY}
      />
    </View>
  )
}
