import * as React from 'react'
import { StyleSheet, Text, View } from 'react-native'
import Footer from '../../common/Footer'

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 16,
  },
  headline: {
    fontSize: 20,
    textAlign: 'center',
    marginVertical: 28,
  },
  contentSection: {
    marginBottom: 30,
  },
  content: {
    fontSize: 16,
    letterSpacing: 0.3,
    lineHeight: 26,
  },
})

export default function Terms() {
  return (
    <View style={styles.container}>
      <Text style={styles.headline}>
        アイケアアンバサダー{'\n'}byビジョナリーHD{'\n'}利用規約
      </Text>

      <View style={styles.contentSection}>
        <Text style={styles.content}>
        第1条（プログラム内容）
          {'\n'}
          1.　株式会社ビジョナリーホールディングス（以下「当社」といいます）が提供する『アイケアアンバサダーbyビジョナリーHD』
          （以下「本サービス」といいます）は、当社または当社グループ会社（当社グループ会社は、当社ホームページに掲載されています）
          の店舗にて商品購入またはサービス利用を行い本サービスの登録を行ったLINEユーザー（以下「紹介元ユーザー」といいます）が、
          他のLINEユーザーである被紹介者（以下「紹介先ユーザー」といいます）に対し、当社および当社グループ会社の店舗にて
          利用可能なクーポン付きのLINEメッセージを送信（発行）し、紹介先ユーザーが、紹介元ユーザーから発行された当該クーポンを、
          当社または当社グループ会社の店舗にて使用し商品購入またはサービス利用を行うことで、紹介元ユーザーに、
          紹介特典チケット（以下「特典チケット」といいます）が付与されるプログラムです。
          {'\n'}
          2.　紹介元ユーザーに付与された特典チケットは、Amazonギフト券等の特典と交換でき、
          当該紹介元ユーザーが紹介した紹介先ユーザーの人数、紹介先ユーザーが購入した商品および利用したサービス等の品数等に応じて、
          紹介元ユーザーに特典チケットが継続して付与されます。
          {'\n'}
          3.　当社は、理由の如何を問わず、いつでも本サービスの利用規約を変更し、また、本サービスを中断もしくは終了することができます。
          本サービスの利用規約の変更、本サービスの中断もしくは終了前にすでに本サービスの利用規約に従って獲得された特典チケットは、
          本サービスの中断もしくは終了と同時に全て無効となります。
        </Text>
      </View>

      <View style={styles.contentSection}>
        <Text style={styles.content}>
        第2条 (会員)
          {'\n'}
          1. 「会員」とは、当社が定める手続に従い本規約に同意の上、入会の申し込みを行う個人をいいます。
          {'\n'}
          2. 「会員情報」とは、会員が当社に開示した会員の属性に関する情報および会員の取引に関する履歴等の情報をいいます。
          {'\n'}
          3. 本規約は、すべての会員に適用され、登録手続時および登録後にお守りいただく規約です。
        </Text>
      </View>

      <View style={styles.contentSection}>
        <Text style={styles.content}>
        第3条（登録）
        {'\n'}
        1.　本サービスは、当社または当社グループ会社の会員登録を行い、本サービスの登録をすることで利用することができます。
        {'\n'}
        2.　会員登録するときは、当社または当社グループ会社の店舗から発行されたメンバーズカードに記載されているメンバーズカード番号をご用意頂き、
        会員登録画面にアクセスし、画面の説明に従い必要事項を入力して登録してください。登録にはメンバーズカード番号及びメールアドレスが必要です。
        </Text>
      </View>

      <View style={styles.contentSection}>
        <Text style={styles.content}>
        第4条 (変更)
        {'\n'}
        1. 会員は、当社に届け出たメンバーズカード番号・メールアドレスに変更があった場合には、速やかに当社に連絡するものとします。
        {'\n'}
        2. 変更登録がなされなかったことにより生じた損害について、当社及び当社グループ会社は一切責任を負いません。
        また、変更登録がなされた場合でも、変更登録前にすでに手続がなされた取引は、
        変更登録前の情報に基づいて行われますのでご注意ください。
        </Text>
      </View>

      <View style={styles.contentSection}>
        <Text style={styles.content}>
        第5条（登録解除）
        {'\n'}
        登録した会員情報を解除したい場合は下記の「お問い合わせフォーム」もしくはフリーダイヤルにてご連絡ください。
        {'\n'}
        「お問い合わせフォーム」https://www.meganesuper.net/shop/a/aL-QA/　
        {'\n'}
        ※内容欄にお問い合わせ内容を記載してください。
        {'\n'}
        フリーダイヤル：0120-209-060
        </Text>
      </View>

      <View style={styles.contentSection}>
        <Text style={styles.content}>
        第6条 (会員資格の喪失及び賠償義務)
        {'\n'}
        1.会員が、会員資格取得申込の際に虚偽の申告をしたとき、当社の店舗またはECサイトでの販売による代金支払債務を怠ったとき、
        その他当社が会員として不適当と認める事由があるときは、当社は、会員資格を取り消すことができます。
        {'\n'}
        2. 会員が、以下の各号に定める行為をしたときは、これにより当社及びグループ会社が被った損害を賠償する責任を負います。
        {'\n'}
        (1) 当ホームページ及びグループ会社ホームページ(https://www.visionaryholdings.co.jp/)にアクセスして情報を改ざんしたり、
        当ホームページに有害なコンピュータープログラムを送信するなどして、当社及びグループ会社の営業を妨害すること
        {'\n'}
        (2)当社及びグループ会社が扱う商品の知的所有権を侵害する行為をすること
        {'\n'}
        (3)その他、この利用規約に反する行為をすること
        </Text>
      </View>

      <View style={styles.contentSection}>
        <Text style={styles.content}>
        第7条 (会員情報の取扱い)
        {'\n'}
        1. 当社及びグループ会社は、原則として会員情報を会員の事前の同意なく第三者に対して開示することはありません。
        ただし、次の各号の場合には、会員の事前の同意なく、当社は会員情報その他のお客様情報を開示できるものとします。
        {'\n'}
        (1)法令に基づき開示を求められた場合
        {'\n'}
        (2)当社の権利、利益、名誉等を保護するために必要であると当社が判断した場合
        {'\n'}
        2. 会員情報につきましては、当社の「個人情報保護への取組み」に従い、当社が管理します。
        当社は会員情報を、会員への本サービス提供、本サービス内容の向上、本サービスの利用促進、
        本サービスの認知向上、および本サービスの健全かつ円滑な運営の確保を図る目的のために利用することができるものとします。
        {'\n'}
        3. 当社は、会員に対して、メールマガジンその他の方法による情報提供(広告を含みます)を行うことができるものとします。
        会員が情報提供を希望しない場合は、当社所定の方法に従い、その旨を通知して頂ければ、情報提供を停止します。
        ただし、本サービス運営に必要な情報提供につきましては、会員の希望により停止をすることはできません。
        </Text>
      </View>

      <View style={styles.contentSection}>
        <Text style={styles.content}>
        第8条（特典チケット、各種条件、禁止事項）
        {'\n'}
        1.　特典チケットとは、紹介元ユーザーが発行するクーポンを、
        紹介先ユーザーが当社または当社グループ会社の店舗にて使用し商品購入またはサービス利用を行った際、
        紹介元ユーザーに付与されるチケットであり、紹介元ユーザーは、特典チケットを、様々な特典と引き換えることができます。
        {'\n'}
        2.　特典チケット付与条件
        {'\n'}
        特典チケットは、紹介元ユーザーが発行するクーポンを、
        紹介先ユーザーが当社または当社グループ会社の店舗にて使用し商品購入またはサービス利用を行った際、
        紹介元ユーザーに、以下のとおり付与されます。
        {'\n'}
        (1)メガネ37,000円（税込）、コンタクトレンズ10,000円（税込）、
        補聴器200,000円（税込）の金額ごとの購入で1枚
        {'\n'}
        (2)コンタクト定期便サービス　加入時に1枚
        {'\n'}
        (3)メガネハイパー保証サービス・子供安心プランサービス加入時に1枚
        {'\n'}
        3.　特典チケットの対象外商品・サービス
        {'\n'}
        SALE・ハッピーバック等のお買い得商品、検査・調整・リラクゼーション等のサービスには、紹介先ユーザーはクーポン利用不可です。
        {'\n'}
        4.　特典チケットの確認・交換
        {'\n'}
        LINE公式アカウント内から起動出来る特典チケット画面から特典チケットの確認ができます。
        {'\n'}
        同画面に記載されている『交換』をクリックすると、特典チケットをAmazonギフト券等の特典と交換できます。
        {'\n'}
        5.　特典チケットと交換できる特典について
        {'\n'}
        Amazonギフト券、カタログギフト、体験ギフト等、時期やたまったチケットの枚数に応じて様々な内容をご用意しております。
        {'\n'}
        6.　特典チケット交換の取消・変更不可
        {'\n'}
        特典チケット画面にて『交換する』を誤ってクリックした場合でも、取り消しはできません。
        また、交換後に別な特典に変更することもできません。十分に確認した上で交換を行ってください。
        {'\n'}
        7.　特典チケットの引換期限
        {'\n'}
        紹介元ユーザーに特典チケットが付与されてから１年です。特典チケット画面に表示されるチケットに引換期限が記載されており、
        引換期限が切れた特典チケットは無効となり、期限後はいかなる理由があっても特典と引き換えることはできません。
        特典チケットの引換期限の延長等はできません。当社及びグループ会社は、特典チケットの失効に関する一切の責任を負わず、
        失効した特典チケットを補償するいかなる責任も負いません。
        {'\n'}
        ただし、紹介元ユーザーの故意又は過失以外の理由で紹介元ユーザーの端末から特典チケットがなくなった場合は、
        サーバーに付与記録があり、かつ引き換え期限内であれば再度付与いたします。付与記録がなければ付与は行えません。
        {'\n'}
        {'\n'}
        8.　交換後の特典の売買禁止
        {'\n'}
        紹介元ユーザーが、特典チケットを交換して取得したカタログギフト等の特典を、第三者に譲渡（有償のみ、無償は除く）することはできません。
        紹介元ユーザーが、本サービスを他のLINEユーザーに紹介する際に発行するクーポン、特典チケットを交換して得た特典を、金品と交換すること、
        インターネットのオークションサイトやフリーマーケットサイトに出品することその他いかなる態様による譲渡行為を禁じます。
        </Text>
      </View>


      <View style={styles.contentSection}>
        <Text style={styles.content}>
        第9条（システムトラブルへの対応）
        {'\n'}
        1. 当社は、本サービスに使用する電子機器、ソフトウェアなどのシステムにつき、
        その時点における一般の技術水準に従って合理的な保守および運用を行います。
        {'\n'}
        2. 当社は、電子機器、ソフトウェアなどの不具合、通信回線の障害、
        第三者による不正アクセス等によって生じた障害などのシステムトラブルに起因して、
        本サービスの利用者に付与された特典チケットの内容等に異常が生じた場合には、
        その時点における一般の技術水準に従って合理的な措置を講じます。
        </Text>
      </View>

      <View style={styles.contentSection}>
        <Text style={styles.content}>
        第10条（サービスの終了、停止、変更等）
        {'\n'}
        1. 当社は、天災、事変、その他の非常事態が発生し、もしくは発生する恐れのあるときは、
        本サービスの利用者に対して事前に公表または通知することなく、本サービスの全部、
        または一部の提供を停止し、または内容を変更する措置を取ることができるものとします。　
        {'\n'}
        2. 当社は、システムの保守等、本サービスの維持管理に必要な作業のため、
        必要な期間サービスの提供を停止することができるものとします。この場合、当社は、本サービスの利用者に対し、
        事前にホームページやLINE公式アカウント等で公表または通知します。ただし、緊急の場合においてはこの限りではありません。　
        {'\n'}
        3. 当社は、営業上その他の理由により、本サービスを中断もしくは終了し、
        または本サービスの変更を行うことができるものとします。
        {'\n'}
        4. 当社及びグループ会社は、前各項による本サービスの停止、中断、終了、変更等によって、
        本サービスの利用者に何らかの損害、不利益が生じた場合であっても、一切責任を負わないものとします。
        </Text>
      </View>

      <View style={styles.contentSection}>
        <Text style={styles.content}>
        第11条（免責）
        {'\n'}
        1.　 当社及びグループ会社は、第三者が当該紹介元ユーザーの特典チケットを利用して
        生じた損害について、一切の責任を負いません。
        {'\n'}
        2.　 当社及びグループ会社は、紹介元ユーザーが、特典チケットと交換して入手したあらゆる特典の
        紛失・盗難・破損・有効期限の経過による失効その他の効力喪失について、一切の損害賠償責任を負わず、
        また、交換された特典チケット及び交換して入手した特典の再発行や払い戻しの義務その他一切の責任を負いません。
        {'\n'}
        ３､　当社及びグループ会社は、特典としてのギフト券等の送付時に際に生ずる送付物の遅延、
        紛失、破損などのすべての事故について、一切の損害賠償責任を負わず、
        また、交換された特典チケット及び交換して入手した特典の再発行や払い戻しの義務その他一切の責任を負いません。
        </Text>
      </View>

      <View style={styles.contentSection}>
        <Text style={styles.content}>
        第12条（本規約の改定）
        {'\n'}
        当社は、本規約を変更した場合、あらかじめ、当社のホームページまたは本サービスの情報画面に掲載する方法によって周知し、
        当該掲載によって、変更後の本規約が当然に適用されるものとします。
        </Text>
      </View>

      <View style={styles.contentSection}>
        <Text style={styles.content}>
        第13条 (準拠法、管轄裁判所)
        {'\n'}
        本規約に関して紛争が生じた場合、準拠法は日本法とし、
        東京地方裁判所を第一審の専属的合意管轄裁判所とします。
        </Text>
      </View>

      <View style={styles.contentSection}>
        <Text style={styles.content}>
        利用規約に関するお問い合わせは下記へお願い致します。
        {'\n'}
        下記の「お問い合わせフォーム」もしくはフリーダイヤルにてご連絡ください。
        {'\n'}
        「お問い合わせフォーム」https://www.meganesuper.net/shop/a/aL-QA/　
        {'\n'}
        ※内容欄にお問い合わせ内容を記載してください。
        {'\n'}
        フリーダイヤル：0120-209-060
        </Text>
      </View>
      <Footer />
    </View>
  )
}
