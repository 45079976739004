import * as React from 'react'
import { Image, StyleSheet, Text, TextInput, View } from 'react-native'
import { COLORS, FONTS } from '../../../../constants'
import ValidationErrors from '../../../common/ValidationErrors'
import { validateInputMinLength } from './FormValidation'

const styles = StyleSheet.create({
  label: {
    marginLeft: 8,
    fontSize: 16,
    fontFamily: FONTS.MAIN_FONT,
  },
  requiredLabel: {
    fontSize: 16,
    minWidth: 42,
    textAlign: 'center',
    fontFamily: FONTS.MAIN_FONT,
    color: COLORS.BACKGROUND,
    backgroundColor: COLORS.ACCENT,
    padding: 4,
    letterSpacing: 0,
  },
  labelWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    marginHorizontal: 16,
  },
  input: {
    height: 48,
    margin: 12,
    marginHorizontal: 16,
    borderWidth: 1,
    padding: 8,
    fontSize: 16,
    textAlign: 'left',
    letterSpacing: 0.5,
  },
  topSpacing: {
    marginTop: 28,
  },
  cardSample: {
    marginHorizontal: 16,
    height: 184,
    width: 288,
  },
  cardSampleWrapper: {
    alignItems: 'center',
  },
})

// Set Min length to be 19 because this contains 3 whitespaces.
const MIN_LENGTH = 19
const MIN_LENGTH_USERINPUT = 16
const ERR_MESSAGE_MIN = 'メンバーズカード番号は' + MIN_LENGTH_USERINPUT + '桁で入力してください'

interface Props {
  // MembersCardNumber
  membersCardNumber: string
  setMembersCardNumber: (MembersCardNumber: string) => void
  setMembersCardNumberValidation: (isValid: boolean) => void
}

export default function MembersCardNumber(props: Props) {
  const { membersCardNumber: value, setMembersCardNumber, setMembersCardNumberValidation: setValid } = props

  const [errors, setErrors] = React.useState<Array<string>>([])
  const validateAll = React.useCallback(() => {
    const errorMessages = [validateInputMinLength(value, MIN_LENGTH, ERR_MESSAGE_MIN)].flat()
    setErrors(errorMessages)
    setValid(errorMessages.length === 0)
  }, [value, setErrors, setValid])

  // Max length and non-number values will be filtered by this replace expression.
  const inputWithSpaces = (input: string) => {
    const regexp = /^(\d{1,7})?(\d{1,3})?(\d{1,3})?(\d{1,3})?$/
    const digits = input.replace(/[^0-9]/g, '')
    const withSpaces = regexp
      .exec(digits)
      ?.slice(1)
      .filter(e => e)
      .join(' ')

    return withSpaces || ''
  }

  const onChangeText = (newValue: string) => {
    setMembersCardNumber(inputWithSpaces(newValue))
  }
  const isFirstRendering = React.useRef(true)
  React.useEffect(() => {
    if (isFirstRendering.current) {
      isFirstRendering.current = false
      return
    }
    validateAll()
  }, [validateAll])

  return (
    <View style={styles.topSpacing}>
      <View style={styles.labelWrapper}>
        <Text style={styles.requiredLabel}>必須</Text>
        <Text style={styles.label}>メンバーズカード番号を入力してください</Text>
      </View>
      <ValidationErrors errors={errors} />
      <TextInput
        onChangeText={onChangeText}
        value={value}
        style={styles.input}
        autoCapitalize="none"
        autoCorrect={false}
        clearButtonMode="unless-editing"
        keyboardType="phone-pad"
        textContentType="none"
        placeholder="0000000 000 000 000"
        placeholderTextColor={COLORS.LIGHTGRAY}
        maxLength={19} // Max length is restricted here instead of form validation.
      />
      <View style={styles.cardSampleWrapper}>
        <Image style={styles.cardSample} source={require('../../../../assets/images/MembersCardSample.jpg')} />
      </View>
    </View>
  )
}
