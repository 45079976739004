import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SendLINECoupon from '../components/screens/SendLINECoupon'
import { Domain, Modules, Usecases } from '../core'
import { setCustomerFeedback } from '../core/modules/customerFeedback'

export default function SendLINECouponContainer() {
  const surveyContents = useSelector((state: Modules.AppState) => state.surveyContents)
  const couponContents = useSelector((state: Modules.AppState) => state.couponContents)
  const dispatch = useDispatch()
  const actions = {
    createCoupon(feedbacks: Domain.LINECoupon.SurveyFeedBack) {
      dispatch(Usecases.CreateCoupon.sendSurveyFeedbackAndCreateCoupon(feedbacks))
      dispatch(setCustomerFeedback(feedbacks.questionnaires))
    },
    getSurveyContents() {
      dispatch(Usecases.StaticData.getSurveyContents())
    },
    getCouponContents() {
      const reloader = () => {
        dispatch(Usecases.StaticData.getCouponContents(reloader))
      }
      dispatch(Usecases.StaticData.getCouponContents(reloader))
    },
  }

  return <SendLINECoupon couponContents={couponContents} surveyContents={surveyContents} actions={actions} />
}
