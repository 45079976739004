import * as Domain from '../domain'

type MembersCard = Domain.MembersCard.Entity | null

const initialState = null

export function createInitialState(): MembersCard {
  return initialState
}

export const SET_MEMBERS_CARD = 'members-card/set' as const
export const SET_REGISTRASTION_COMPLETE = 'regitration-complete/set' as const
export const CLEAR_MEMBERS_CARD = 'members-card/clear' as const

export function setMembersCard(membersCard: MembersCard) {
  return {
    type: SET_MEMBERS_CARD,
    payload: {
      membersCard,
    },
  }
}

export function setRegitrationComplete(membersCard: MembersCard) {
  return {
    type: SET_REGISTRASTION_COMPLETE,
    payload: {
      membersCard,
    },
  }
}

export function clearMembersCard() {
  return {
    type: CLEAR_MEMBERS_CARD,
  }
}

type SetMembersCardAction = ReturnType<typeof setMembersCard>
type SetRegitrationComplete = ReturnType<typeof setRegitrationComplete>
type ClearMembersCardAction = ReturnType<typeof clearMembersCard>
type Action = SetMembersCardAction | SetRegitrationComplete | ClearMembersCardAction

export function reducer(state = createInitialState(), action: Action) {
  switch (action.type) {
    case SET_MEMBERS_CARD:
      return action.payload.membersCard
    case SET_REGISTRASTION_COMPLETE:
      return Object.assign({}, action.payload.membersCard, { registrationComplete: true })
    case CLEAR_MEMBERS_CARD:
      return initialState
    default:
      return state
  }
}
