import * as React from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { COLORS, FONTS } from '../../../constants'

const styles = StyleSheet.create({
  container: {
    marginTop: 20,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  textLabel: {
    fontSize: 16,
    fontFamily: FONTS.MAIN_FONT,
  },
  ticketTotalLabel: {
    fontSize: 20,
    fontFamily: FONTS.MAIN_FONT,
    marginHorizontal: 5,
    color: COLORS.ACCENT,
  },
})

interface Props {
  untilSpecialTicket: number
}

export default function UntilSpecialTicket(props: Props) {
  return (
    <View style={styles.container}>
      <Text style={styles.textLabel}>あと</Text>
      <Text style={styles.ticketTotalLabel}>{props.untilSpecialTicket}</Text>
      <Text style={styles.textLabel}>枚</Text>
    </View>
  )
}
