import * as React from 'react'
import { StyleSheet, Text } from 'react-native'
import { Link } from 'react-router-dom'
import { COLORS } from '../../../constants'

const styles = StyleSheet.create({
  linkText: {
    fontSize: 16,
    textDecorationLine: 'underline',
    color: COLORS.ACCENT,
    width: '100%',
  },
})

interface Props {
  id: string
  title: string
}

export default function QAList(props: Props) {
  return (
    <Text style={styles.linkText}>
    <Link
      to={{
        hash: props.id,
      }}>
      ・{props.title}
    </Link>
    </Text>
  )
}
