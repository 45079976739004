import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ChooseShareOption from '../components/screens/ChooseShareOption'
import { Modules, Usecases } from '../core'

export default function ChooseShareOptionContainer() {
  const membersCard = useSelector((state: Modules.AppState) => state.membersCard)
  const dispatch = useDispatch()
  const actions = {
    getMembersCard() {
      const reloader = () => {
        dispatch(Usecases.GetMembersCard.getMembersCard(reloader))
      }
      dispatch(Usecases.GetMembersCard.getMembersCard(reloader))
    },
  }

  return <ChooseShareOption membersCard={membersCard} actions={actions} />
}
