import React from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { COLORS } from '../../../constants'
import modalContainerStyle from './Modal.module.css'

const styles = StyleSheet.create({
  modal: {
    width: '100%',
    maxWidth: 264,
    borderRadius: 5,
    backgroundColor: COLORS.WHITE,
  },
  modalLabels: {
    flex: 1,
    marginTop: 24,
  },
  modalTitle: {
    fontSize: 20,
    fontWeight: '500',
    textAlign: 'center',
    marginBottom: 16,
    marginHorizontal: 16,
  },
  modalDescription: {
    fontSize: 16,
    textAlign: 'center',
    marginHorizontal: 24,
  },
})

interface Props {
  needsToShow: boolean
  title?: string
  description?: string
  children: React.ReactNode
}

export default function Modal(props: Props) {
  const { needsToShow, children, title, description } = props

  if (!needsToShow) {
    return null
  }

  return (
    <div className={modalContainerStyle.modalContainer}>
      <View style={styles.modal}>
        <View style={styles.modalLabels}>
          <Text style={styles.modalTitle}>{title}</Text>
          {!!description && <Text style={styles.modalDescription}>{description}</Text>}
        </View>
        {children}
      </View>
    </div>
  )
}
