import { Dispatch } from 'redux'
import { Domain } from '..'
import { Api } from '../../lib'
import { setRegitrationComplete } from '../modules/membersCard'

type Data = Domain.MembersCard.Entity | null

function didCallRegisterMember(dispatch: Dispatch, data: Data) {
  dispatch(setRegitrationComplete(data))
}

export function registerMember(data: Data) {
  return async function(dispatch: Dispatch) {
    await Api.callApi(Api.HttpMethod.post, 'cards', JSON.stringify(data), dispatch, didCallRegisterMember)
  }
}
