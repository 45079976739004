import * as React from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { COLORS } from '../../constants'

const styles = StyleSheet.create({
  container: {
    marginTop: 12,
    marginHorizontal: 16,
    backgroundColor: COLORS.VALIDATION_ERR,
    padding: 8,
  },
  error: {
    fontSize: 16,
  },
})

interface Props {
  errors: Array<string>
}

export default function ValidationErrors(props: Props) {
  if (props.errors.length === 0) {
    return null
  }

  return (
    <View style={styles.container}>
      {props.errors.map((error, index) => (
        <Text style={styles.error} key={index}>
          {error}
        </Text>
      ))}
    </View>
  )
}
