import * as React from 'react'
import { Image, StyleSheet, Text, View } from 'react-native'

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
  },
  headlineContainer: {
    marginTop: 28,
  },
  headlineText: {
    textAlign: 'center',
    marginHorizontal: 16,
    fontSize: 20,
  },
  giftImage: {
    marginVertical: 12,
    marginHorizontal: 74,
    height: 135,
    width: 172,
  },
  subHeadlineText: {
    textAlign: 'center',
    marginHorizontal: 16,
    fontSize: 16,
  },
})

export default function Title() {
  return (
    <View style={styles.container}>
      <View style={styles.headlineContainer}>
        <Text style={styles.headlineText}>アイケアアンバサダー{'\n'}byビジョナリーHDって何？</Text>
      </View>
      <View style={styles.headlineContainer}>
        <Text style={styles.headlineText}>クーポンを友だちに送って{'\n'}豪華商品をもらおう！</Text>
      </View>
      <Image style={styles.giftImage} source={require('../../../assets/images/SpecialGift.png')} />
      <Text style={styles.subHeadlineText}>
        友だちに送ったクーポンで{'\n'}商品が購入されるとお得なチケットが{'\n'}もらえます
      </Text>
    </View>
  )
}
