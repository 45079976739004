import * as React from 'react'
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { Domain } from '../../../core'
import { useNavigate } from '../../../lib/hooks'
import Modal from '../../common/Modal'
import generateFlexMessage from './messageTemplate'
const { liff } = window

interface Props {
  customerFeedback: Array<Domain.LINECoupon.Survey>
  couponContents: Domain.LINECoupon.CouponContents
  couponData: Domain.LINECoupon.CouponData | null
}

const styles = StyleSheet.create({
  modalButton: {
    justifyContent: 'center',
    alignItems: 'center',
    marginVertical: 36,
  },
  modalButtonLabel: {
    fontSize: 16,
  },
})

// Send LINE Message with Approval No
export default function ShareTargetPicker(props: Props) {
  const { customerFeedback, couponContents, couponData } = props

  const navigate = useNavigate('/share-option', {})
  const navigateBackToShareCouponPage = useNavigate('/send-line-coupon', {})

  const [needsToShow, setNeedsToShow] = React.useState(false)
  const [lineUpdateNeedsToShow, setLineUpdateNeedsToShow] = React.useState(false)

  const onPress = () => {
    setNeedsToShow(false)
    setLineUpdateNeedsToShow(false)
    navigateBackToShareCouponPage()
  }

  React.useEffect(() => {
    if (!couponData || !couponContents) {
      return
    }

    const execShareTargetPicker = async (
      /* eslint-disable no-shadow */
      approvalNo: string,
      couponContents: Domain.LINECoupon.CouponContents,
      customerFeedback: Domain.LINECoupon.Survey[],
    ) => {
      try {
        const profile = await liff.getProfile()
        if (liff.isApiAvailable('shareTargetPicker')) {
          const response = await liff.shareTargetPicker([
            {
              type: 'flex',
              altText: 'アイケアアンバサダーからクーポンが届きました',
              contents: generateFlexMessage(profile.displayName, approvalNo, couponContents, customerFeedback),
            },
          ])

          if (response) {
            // Close Liff Window.
            liff.closeWindow()
          } else {
            const [majorVer, minorVer] = (liff.getLineVersion() || '').split('.')
            if (parseInt(majorVer, 10) === 10 && parseInt(minorVer, 10) < 11) {
              // LINE 10.3.0 - 10.10.0
              // Old LINE will access here regardless of user's action
              // TargetPicker was opened at least. Whether succeeded to send message is unclear'
              navigateBackToShareCouponPage()
            } else {
              // LINE 10.11.0 -
              // sending message canceled
              // 'TargetPicker was closed!'
              navigateBackToShareCouponPage()
            }
          }
        } else {
          setLineUpdateNeedsToShow(true)
        }
      } catch (e) {
        setNeedsToShow(true)
      }
    }
    execShareTargetPicker(couponData.approvalNo, couponContents, customerFeedback)
  }, [navigate, navigateBackToShareCouponPage, customerFeedback, couponContents, couponData])
  return (
    <View>
      <Modal
        needsToShow={lineUpdateNeedsToShow}
        description={
          '紹介クーポンの送信には、LINEバージョン10.3.0以降が必要です。iOS、Android各ストアにてLINEバージョンの更新をお願いします。'
        }>
        <TouchableOpacity style={styles.modalButton} onPress={onPress}>
          <Text style={styles.modalButtonLabel}>OK</Text>
        </TouchableOpacity>
      </Modal>
      <Modal needsToShow={needsToShow} description={'LINEの送信に失敗しました'}>
        <TouchableOpacity style={styles.modalButton} onPress={onPress}>
          <Text style={styles.modalButtonLabel}>OK</Text>
        </TouchableOpacity>
      </Modal>
    </View>
  )
}
