import * as React from 'react'
import { Linking, StyleSheet, Text, View } from 'react-native'
import { COLORS, FONTS } from '../../../constants'

const styles = StyleSheet.create({
  container: {
    marginTop: 28,
    marginBottom: 12,
    justifyContent: 'center',
    alignItems: 'center',
  },
  textLabel: {
    fontSize: 14,
    fontFamily: FONTS.MAIN_FONT,
    color: COLORS.LINK,
    textDecorationLine: 'underline',
  },
})

const TICKET_DESCRIPTION_LINK = `${process.env.REACT_APP_BASE_PATH}/lp`

export default function TicketDescription() {
  const OpneUrl = (url: string) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    Linking.openURL(url, '_self')
  }

  return (
    <View style={styles.container}>
      <Text style={styles.textLabel} onPress={() => OpneUrl(TICKET_DESCRIPTION_LINK)}>
        チケットを集めると何がもらえるの？
      </Text>
    </View>
  )
}
