import * as Domain from '../domain'

type RedeemResult = Domain.Ticket.Entity

export function createInitialState(): RedeemResult | null {
  return null
}

export const SET_TICKET_REDEEM_RESULT = 'ticket-redeem-result/set' as const
export const CLEAR_TICKET_REDEEM_RESULT = 'ticket-redeem-result/clear' as const

export function setTicketRedeemResult(ticketRedeemResult: RedeemResult) {
  return {
    type: SET_TICKET_REDEEM_RESULT,
    payload: {
      ticket: ticketRedeemResult,
    },
  }
}

export function clearTicketRedeemResult() {
  return {
    type: CLEAR_TICKET_REDEEM_RESULT,
  }
}

type SetTicketRedeemResultAction = ReturnType<typeof setTicketRedeemResult>
type ClearTicketRedeemResultAction = ReturnType<typeof clearTicketRedeemResult>
type Action = SetTicketRedeemResultAction | ClearTicketRedeemResultAction

export function reducer(state = createInitialState(), action: Action) {
  switch (action.type) {
    case SET_TICKET_REDEEM_RESULT:
      return action.payload.ticket
    case CLEAR_TICKET_REDEEM_RESULT:
      return null
    default:
      return state
  }
}
