import * as React from 'react'
import { StyleSheet, Text, TouchableOpacity, ViewStyle } from 'react-native'
import { COLORS } from '../../../../constants'

const styles = StyleSheet.create({
  container: {
    height: 52,
    marginHorizontal: 16,
    alignItems: 'center',
    backgroundColor: COLORS.ACCENT,
    color: COLORS.BACKGROUND,
  },
  button: {
    margin: 12,
    fontSize: 20,
    color: COLORS.BACKGROUND,
  },
  disabled: {
    margin: 12,
    fontSize: 20,
    backgroundColor: COLORS.DISABLED,
    color: COLORS.BACKGROUND,
  },
})

interface Props {
  disabled?: boolean
  style: ViewStyle
  createCoupon: () => void
}

export default function SendLINECouponButton(props: Props) {
  return (
    <TouchableOpacity
      onPress={props.createCoupon}
      style={[styles.container, props.style, props.disabled ? styles.disabled : styles.button]}
      disabled={props.disabled}>
      <Text style={styles.button}>友達を選択する ＞</Text>
    </TouchableOpacity>
  )
}
