import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SpecialGift from '../components/screens/SpecialGift'
import { Modules, Usecases } from '../core'

export default function SpecialGiftContainer() {
  const dispatch = useDispatch()
  const tickets = useSelector((state: Modules.AppState) => state.tickets)
  const ticketRedeemResult = useSelector((state: Modules.AppState) => state.ticketRedeemResult)
  const actions = {
    redeemTicket(ticketId: string, incentiveType: string) {
      dispatch(Usecases.RedeemTicket.redeemTicket(ticketId, incentiveType))
    },
    getTickets() {
      dispatch(Usecases.Tickets.getTickets())
    },
    clearTicketRedeemResult() {
      dispatch(Modules.TicketRedeemResult.clearTicketRedeemResult())
    },
  }

  return <SpecialGift tickets={tickets} ticketRedeemResult={ticketRedeemResult} actions={actions} />
}
