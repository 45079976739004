import * as React from 'react'
import { Image, StyleSheet, Text, View } from 'react-native'
import { COLORS } from '../../../../constants'
import { Domain } from '../../../../core'

const styles = StyleSheet.create({
  topSpacing: {
    marginTop: 28,
  },
  couponList: {
    backgroundColor: COLORS.ACCENT_LIGHT,
    paddingVertical: 12,
  },
  couponItem: {
    fontSize: 16,
    textAlign: 'left',
    marginLeft: 20,
    marginVertical: 12,
  },
  couponItemWrapper: {
    flexDirection: 'row',
  },
  bullet: {
    fontSize: 16,
    textAlign: 'left',
    marginLeft: 20,
    marginVertical: 12,
    color: COLORS.ACCENT,
  },
  couponSample: {
    marginHorizontal: 16,
    height: 367,
    width: 288,
  },
  couponSampleWrapper: {
    alignItems: 'center',
    marginVertical: 20,
  },
})

interface Props {
  // Coupon
  couponContents: Domain.LINECoupon.CouponContents
}

export default function CouponContents(props: Props) {
  const { couponContents } = props

  const couponItems = couponContents.couponItems.map((item, i) => {
    return (
      <View key={i} style={styles.couponItemWrapper}>
        <Text style={styles.bullet}>{'■'}</Text>
        <Text style={styles.couponItem}>{item.couponDescription}</Text>
      </View>
    )
  })

  return (
    <View>
      <View style={styles.topSpacing}>
        { couponItems.length > 0 ? <View style={styles.couponList}>{couponItems}</View> : <></>}
      </View>
      <View style={styles.couponSampleWrapper}>
        <Image style={styles.couponSample} source={require('../../../../assets/images/CouponSample_20221214.jpg')} />
      </View>
    </View>
  )
}
