import { Dispatch } from 'redux'
import { Api } from '../../lib'
import { LINECoupon } from '../domain'
import { setCouponData } from '../modules/couponData'

type FeedbackData = LINECoupon.SurveyFeedBack
type CouponData = LINECoupon.CouponData

function didCallCouponsApi(dispatch: Dispatch, data: CouponData) {
  dispatch(setCouponData(data))
}

export function sendSurveyFeedbackAndCreateCoupon(feedbacks: FeedbackData) {
  return async function(dispatch: Dispatch) {
    //  Send Survey Feedbacks
    await Api.callApi(Api.HttpMethod.post, 'coupons', JSON.stringify(feedbacks), dispatch, didCallCouponsApi)
  }
}
